@import url('https://fonts.googleapis.com/css?family=Roboto');

html,
body {
  margin: 0;
  width: 100%;
  background: #131313;
  color: #ccc;
  font-family: arial, sans-serif;
  /* font-family: Roboto, sans-serif; */
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
}

* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
#root {
  min-width: 725px;
  width: 100%;
}
.app {
  position: relative;
  min-height: 100vh;
  min-height: 100%;
}

a {
  text-decoration: none;
  color: white;
}

/* a:hover {
  text-decoration: underline;
} */

button:focus {
  outline: none;
}

.header {
  margin: 0;
  padding: 0;
  background: #1b1b1b;
  color: #ccc;
  display: flex;
  line-height: 0.8em;
  border-bottom: 1px solid black;
}

.header-logo {
  padding: 10px 20px 8px 20px;
  border-right: 1px solid black;
}

.header-cue {
  flex: 1;
  flex-direction: column;
  border-left: 1px solid #2d2d2d;
  border-right: 1px solid black;
}
.header-user {
  flex-direction: column;
  border-left: 1px solid #2d2d2d;
  border-right: 1px solid black;
  align-content: flex-end;
}

.storage-panel {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #7d7d7d;
  font-family: Arial;
  font-size: 12px;
  text-align: center;
  width: auto;
  height: 20px;
  text-align: right;
}

.api-status {
  display: block;
  font-size: 12px;
  text-align: right;
  color: white;
}

#apiStatus {
  color: #65b365;
}

.s-ok {
  color: #689c61;
  font-weight: bold;
}

.error {
  color: #d84c4c !important;
}

.error-wrap {
  text-align: center;
}

.error-wrap h3 {
  color: #b78a33;
  margin: 0;
  padding-bottom: 20px;
}

.error-wrap .icon {
  text-align: center;
  font-size: 100px;
  padding: 30px 0 0 0;
}

.error-wrap .help {
  color: #b78a33;
  font-size: 16px;
  text-align: left;
  display: inline-block;
  margin: 20px auto 0 auto;
  background: #0c0c0c;
  padding: 20px;
}

.help .help-icon {
  font-size: 50px;
  text-align: center;
}

.error-wrap p {
  padding: 0;
  margin: 10px 0;
}

.content .big-icon {
  text-align: center;
  font-size: 200px;
  color: #0a0a0a;
  text-shadow: 1px 1px 1px #232323;
  padding: 40px 20px 0 20px;
}

.content h3 {
  text-align: center;
}

#player {
  width: 100%;
  background: #131313;
  box-sizing: border-box;
  display: flex;
  box-shadow: inset 3px 3px 10px #0c0c0c;
  /* border-top: 1px solid black; */
  /* border-bottom: 1px solid #212121; */
}

.nav {
  padding: 0 20px;
  background: #191919;
  display: flex;
  border-top: 1px solid black;
}

.content {
  box-sizing: border-box;
  display: block;
}

.player-right {
  margin-left: auto;
}

.clock {
  font-family: 'Roboto', sans-serif;
  padding: 2px 20px 0 0;
  color: #4e6373;
  font-size: 19px;
  font-weight: bold;
}

.cast-online {
  display: block;
  background: #304e30;
  color: white;
  padding: 3px 10px 2px 10px;
  margin: 1px 20px 0 0;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
}

.cast-offline {
  display: block;
  background: #424242;
  color: #0a0a0a;
  padding: 3px 10px 2px 10px;
  margin: 1px 20px 0 0;
  text-align: center;
  font-size: 8px;
  font-weight: bold;
}

.footer {
  position: relative;
  bottom: 0;
  background: #0c0c0c;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  height: 100px;
  padding: 25px;
  border-top: 1px solid black;
}

footer p {
  font-size: 0.7rem;
  color: gray;
}

.btn,
.drop-btn {
  margin: 0;
  padding: 7px 10px;
  /* background: #0e0e0e; */
  color: #4e4e4e;
  border: none;
  font-weight: bold;
  cursor: pointer;
  font-size: 13px;
  /* box-shadow: 1px 1px 1px #232323; */
  transition: 0.2s;
  /* line-height: 20px;
  padding: 0 10px; */
}

.btn:hover {
  background: #bbb;
}

.nav-btn,
.nav-drop-btn {
  margin: 0;
  color: #707070;
  background: #191919;
  font-weight: bold;
  cursor: pointer;
  font-size: 12px;
  transition: 0.2s;
  height: 30px;
  padding: 0 10px;
  line-height: 32px;
  border-left: 1px solid #252525;
  border-right: 1px solid black;
  align-content: center;
}

.nav-btn:hover,
.nav-drop-btn:hover {
  background: rgb(56, 56, 56);
  color: white;
}

.nav-spacer-left {
  width: 1px;
  height: 30px;
  background: black;
}

.nav-spacer-right {
  width: 1px;
  height: 30px;
  background: #252525;
}

.nav-active {
  background: rgb(56, 56, 56);
  color: white;
}

.nav-user {
  flex-grow: 1;
}

.user-wrap {
  display: flex;
  position: relative;
  justify-content: flex-end;
}

.usermenu-list ul {
  list-style: none;
  margin: 0;
  padding: 3px;
}

.usermenu-list a,
.usermenu-list li {
  cursor: pointer;
  width: 100%;
  color: #4d4d4d;
  display: block;
  padding: 10px;
  font-size: 14px;
  line-height: 0.5;
  transition: 0.2s;
}

.usermenu-list a:hover,
.usermenu-list li:hover {
  background: #ccc;
}

.usermenu-list li {
  margin-top: 10px;
}

.notify-btn {
  border: none;
  background: transparent;
  padding: 2px 0 0 0;
  font-weight: normal;
  font-size: 25px;
  color: #4e4e44;
  cursor: pointer;
  margin: 0;
  box-shadow: 1px 1px 1px transparent;
  position: relatve;
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

.notify-btn:hover {
  color: white;
}

.notifications {
  position: relative;
}

.notification-list {
  max-height: 60vh;
  /* overflow-y: auto; */
  position: absolute;
  z-index: 1;
  min-width: 10vw;
  max-width: 80vw;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background: white;
  box-sizing: border-box;
  right: 0;
  left: auto;
  margin-top: 8px;
  overflow: hidden;
}

.notification-list p {
  padding: 5px 10px;
  color: #555;
  font-size: 14px;
  text-align: center;
}

.usermenu {
  position: relative;
}

.user-btn {
  border: none;
  background: transparent;
  padding: 8px 0 0 0;
  font-weight: normal;
  font-size: 13px;
  color: #6d6d6d;
  font-weight: bold;
  cursor: pointer;
  margin: 0;
  position: relatve;
  transition: 0.2s;
  -webkit-transition: 0.2s;
}

.active {
  color: white;
}

.user-btn:hover {
  color: white;
}

.usermenu-content {
  position: absolute;
  padding-top: 15px;
  right: 0;
  left: auto;
}

.notification-content {
  position: absolute;
  padding-top: 5px;
  right: 0;
  left: auto;
}

.usermenu-list-wrap,
.notification-list-wrap {
  position: relative;
  box-shadow: 2px 2px 5px black;
  z-index: 11;
}

.notification-list ul {
  list-style: none;
  padding: 3px;
  margin: 0;
}

.notification-list li {
  color: #929292;
  font-size: 13px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.notification-list li:hover {
  background: rgb(209, 209, 209);
}

.notification-list li a {
  color: #4d4d4d;
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification-list li a:hover {
  text-decoration: underline;
}

.list-time {
  margin: 2px 0 0 20px;
  font-size: 11px;
}

.list-time:after {
  font-family: 'Font Awesome\ 5 Free';
  /* font-weight: 900; */
  content: '\f017';
  margin-left: 3px;
}

.usermenu-list-wrap:after {
  bottom: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: white;
  border-width: 8px;
  right: 8px;
  top: -16px;
}

.notification-list-wrap:after {
  bottom: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: white;
  border-width: 8px;
  right: 8px;
  top: -8px;
}

.usermenu-list {
  max-height: 60vh;
  overflow-y: auto;
  position: relative;
  z-index: 1;
  min-width: 10vw;
  max-width: 80vw;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background: white;
  box-sizing: border-box;
}

#notifyCount {
  font-size: 9px;
  color: white;
  font-weight: bold;
  margin: 5px 0 0 3px;
  background: #af4a4a;
  padding: 2px 3px 1px 2px;
  float: right;
  text-align: center;
}

/* #notifyCountNew:hover {
  background: white;
} */

#notifyCountNew {
  font-size: 11px;
  color: dimgrey;
  font-weight: bold;
  margin: 2px 0 0 10px;
  background: #2b2b2b;
  padding: 5px 9px;
  text-align: center;
}

.unseen {
  background: #af4a4a !important;
  color: white !important;
}

.brand {
  width: 80px;
}

.brand img {
  width: 100%;
}

.logo {
  width: 140px;
}

.logo img {
  width: 100%;
}

#playingNow {
  text-align: left;
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  padding: 15px 0 0 15px;
}

#playingNowLocal {
  text-align: left;
  margin: 0;
  font-weight: normal;
  font-size: 0.5em;
  padding: 0 0 0 15px;
  /* margin-top: -5px; */
  display: none;
  width: 500px;
  box-sizing: border-box;
}

#nowPlayingLocalMeta {
  width: 500px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 4px;
  font-size: 12px;
  font-weight: bold;
}

#playerControl {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  line-height: 0.7em;
  margin-top: 2px;
}

#localProgressBar {
  background: #272727;
  height: 11px;
  padding: 0;
  cursor: pointer;
  width: 400px;
  min-width: 400px;
}

#localProgress {
  width: 0;
  /* background: #635677; */
  background: #9a6d2f;
  height: 11px;
  display: block;
}

#currentTime {
  margin: 3px 0 0 0;
  font-size: 9px;
  color: #afafaf;
  width: 50px;
}

#currentDuration {
  margin: 3px 0 0 0;
  font-size: 9px;
  color: #afafaf;
  width: 50px;
  text-align: right;
}

#nowPlaying {
  display: none;
  color: #b3903a;
}

#nowPlayingLS {
  display: none;
  color: #7ba27b;
}

.player-controls {
  display: flex;
  box-sizing: border-box;
  padding: 5px 0 6px 20px;
  border-right: 1px solid #060606;
  overflow: hidden;
  box-shadow: -5px 0 5px -5px #333;
  flex: 0 0 195px;
}

#play-btn {
  border: none;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  background: none;
  font-size: 30px;
  color: #d0d0d0;
  cursor: pointer;
  transition: 0.3s;
}

#play-btn:hover,
#mute-btn:hover {
  color: white;
}

#spectrum {
  width: 30px;
  height: 30px;
  margin: 4px 7px 0 12px;
}

#mute-btn {
  border: none;
  padding: 0;
  width: 30px;
  height: 30px;
  background: none;
  font-size: 20px;
  color: #d0d0d0;
  cursor: pointer;
  margin-top: 3px;
  transition: 0.3s;
}

.switch-wrap {
  display: flex;
  flex-direction: column;
  margin: 2px 0 0 10px;
}

.switch {
  border: 0;
  padding: 2px 5px 2px 5px;
  line-height: 10px;
  font-size: 8px;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.switch:focus {
  outline: none;
}

#stream-local {
  background: #383838;
  color: black;
  margin-bottom: 3px;
}

#stream-live {
  color: black;
  background: #383838;
}

#buffering {
  display: none;
  font-size: 10px;
  font-family: Arial;
  color: #cecfd0;
  letter-spacing: 1px;
  font-weight: bold;
  margin: 18px 0 0 15px;
}

#buffering:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  width: 0px;
}

#stream-error {
  display: none;
  font-size: 8px;
  font-family: Arial;
  color: #d05a5a;
  letter-spacing: 1px;
  font-weight: normal;
  margin: 5px 0 0 15px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.pulsate-css {
  animation: pulsate 0.3s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.2;
  height: 30px;
  width: 30px;
  position: relative;
  display: inline-block;
  text-align: center;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.9, 0.9);
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.1, 1.1);
    opacity: 0.3;
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .nav-drop-btn {
  background: rgb(56, 56, 56);
  color: white;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: rgb(56, 56, 56);
  color: white;
  min-width: 140px;
  font-size: inherit;
  /* box-shadow: 0px 3px 8px black; */
  z-index: 1;
  margin: 0 0 0 1px;
}

.dropdown-content > div {
  font-size: 12px;
  font-weight: bold;
  padding: 13px 10px;
  margin: 0;
  line-height: 5px;
  cursor: pointer;
}

.dropdown-content > a {
  font-size: 12px;
  font-weight: bold;
  padding: 13px 10px;
  margin: 0;
  line-height: 5px;
  cursor: pointer;
  display: block;
}

.dropdown-content > a:hover {
  background: #bbb;
  color: #131313;
}

.dropdown-content > div:hover {
  background: #bbb;
  color: #131313;
}

.drop-btn:focus {
  outline: none;
}

/* Alertify shit */

.alert-icon {
  font-size: 60px;
  margin-bottom: 20px;
}

.alertChar {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.alert-p {
  font-size: 17px;
  color: #252525;
}

.alertify-button {
  line-height: 1.2 !important;
  margin: 0 !important;
  text-shadow: none !important;
  box-shadow: none !important;
  font-size: 0.7em !important;
  border: none !important;
}
.alertify-button-ok {
  background-color: #426c9e !important;
  font-weight: normal !important;
  border: none !important;
}

.error-icon {
  width: 100%;
  text-align: center;
  font-size: 50px;
  color: #ce5b57;
  padding-bottom: 20px;
}

.login-wrap {
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

.login-wrap h2 {
  text-align: center;
}
.login-wrap .login-icon {
  font-size: 200px;
  text-align: center;
  color: #252525;
  text-shadow: 1px 1px 1px #484848;
}

#login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.login-logo {
  display: block;
  margin: 0 auto 20px auto;
  width: 400px;
  height: 196px;
  overflow: hidden;
}

.login-logo img {
  width: 100%;
}

.login-error {
  text-align: center;
  color: #a03c3c;
  margin-top: 15px;
}

#login input[type='text'],
#login input[type='password'] {
  padding: 7px;
  margin-bottom: 15px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  min-width: 400px;
  border: none;
}

#login input[type='text'],
#login input[type='password'] {
  outline: none;
}

.login-form p {
  text-align: center;
  line-height: 1.5rem;
  margin-top: 0;
}

.forgot {
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
  cursor: pointer;
}

.forgot:hover {
  text-decoration: underline;
}

.blue {
  background: #315a7d;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: none;
}

.blue:hover {
  background: #4679a7;
}

/* 404 Page */
.disk-wrapper {
  overflow: hidden;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
}
.disk-wrapper .light-left,
.disk-wrapper .light-right {
  width: 35%;
  height: 42%;
  position: absolute;
  background-color: #615951;
  z-index: 20;
  opacity: 0.1;
}
.disk-wrapper .light-left {
  top: 20px;
  left: 20px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-clip-path: polygon(0 0, 100% 0, 66% 100%, 40% 100%);
  clip-path: polygon(0 0, 100% 0, 66% 100%, 40% 100%);
}
.disk-wrapper .light-right {
  -webkit-clip-path: polygon(40% 0, 66% 0, 100% 100%, 0 100%);
  clip-path: polygon(40% 0, 66% 0, 100% 100%, 0 100%);
  right: 20px;
  bottom: 20px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.wrap404 {
  margin: 0 auto;
  width: 300px;
  padding: 50px 0 0 0;
}

.disk {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  background-color: #050608;
  border-radius: 100%;
  position: relative;
  -webkit-animation: circle 2s infinite linear;
  animation: circle 2s infinite linear;
}
.disk .half-circle-top {
  top: 50px;
  border: 1px solid #61595121;
  border-top-left-radius: 130px;
  border-top-right-radius: 130px;
  border-bottom: 0;
}
.disk .half-circle-bottom {
  bottom: 50px;
  border: 1px solid #61595121;
  border-bottom-left-radius: 130px;
  border-bottom-right-radius: 130px;
  border-top: 0;
}
.disk .half-circle-bottom,
.disk .half-circle-top {
  width: 200px;
  height: 100px;
  position: absolute;
  background-color: #050608;
}
.disk .inner-circle {
  width: 90px;
  height: 90px;
  background-color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.disk .inner-circle span {
  color: #242424;
  font-weight: 900;
  font-size: 28px;
  font-family: arial;
}
.disk .inner-circle .dot {
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 100%;
  margin: 5px;
}

@-webkit-keyframes circle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes circle {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.error {
  color: #d2ccc6;
  margin: 30px 0;
}
/* END 404 Page */

/* Upload */
#container {
  background: #1f1f1f;
  padding: 15px 0 0 0;
  border-top: 1px solid #0e0e0e;
  box-shadow: inset 0 7px 9px -7px #060606;
}

.btn-upload {
  background: #3b516b;
  color: #ececec;
  font-weight: normal;
  padding: 10px;
  font-size: 14px;
  box-shadow: none;
}

.btn-upload:hover {
  background: #426c9e;
}

#upload-container {
  padding: 0 0 15px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #000000;
}

#upload-choose-container {
}

#upload-file {
  display: none;
}

#choose-upload-button {
  display: block;
  margin: 0 auto;
}

#upload-file-final-container {
  /* display: none; */
  text-align: center;
}

.showUploadBox {
  padding: 0 0 5px;
  color: white;
}

.showUploadBox .showTitle {
  font-weight: bold;
  margin-bottom: 3px;
}

.showUploadBox .showDJ {
  font-weight: bold;
  font-size: 19px;
}

#file-name {
  color: #86a5bd;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  background: #171717;
  padding: 7px 15px;
  box-shadow: 1px 1px 1px #313131;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
}

#form-inputs select {
  font-size: 15px;
  height: 28px;
  padding: 5px 10px;
  -webkit-border-radius: 0;
  border: 0;
  border-radius: 0;
  outline: 2px solid rgb(255, 255, 255);
  outline-offset: -2px;
  margin-bottom: 10px;
  min-width: 290px;
}

.uploadBtns {
  display: block;
  text-align: center;
}

.uploadBtns .btn {
  padding: 10px 30px;
  font-size: 12px;
}

.uploadBtns .btn-upload-m {
  background: #3b516b;
  color: white;
  font-weight: normal;
}

.uploadBtns .btn-upload-m:hover {
  background: #426c9e !important;
}

.uploadBtns .btn-cancel {
  background: #b3b3b3;
  color: #242424;
  font-weight: normal;
}

.uploadBtns .btn-cancel:hover {
  background: white !important;
}

#form-inputs {
  max-width: 60%;
  margin: 20px auto 0 auto;
}

#form-inputs input[type='text'] {
  padding: 5px;
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #191919;
  margin-bottom: 10px;
}

#form-inputs p {
  text-align: left;
  font-size: 13px !important;
  line-height: 1.3em;
  margin: 0 0 10px 0 !important;
  color: #6f6f6f;
}

#upload-file-final-container h4 {
  margin: 10px 0 15px 0;
}

#upload-button {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  font-weight: bold;
}

#fake-cancel-button {
  vertical-align: middle;
}

#cancel-button {
  /* display: none; */
  vertical-align: middle;
}

.ul-icon {
  margin-right: 7px;
}

#status-message {
  text-align: center;
  color: #c0392b;
  margin: 20px 0 0 0;
}

#progress_outer {
  box-shadow: 1px 1px 1px #2b2b2b;
  margin: 10px 20px 15px;
  padding: 1px;
  background: #131313;
  position: relative;
}

#timeRemaining {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin-top: 4px;
  color: white;
  text-shadow: 1px 1px 1px #2d2d2d;
}

.progress {
  width: 1%;
  /* background: #354d5d; */
  height: 20px;
  background: #354d5d;
  background: -moz-linear-gradient(top, #a7cfdf 0%, #23538a 100%);
  background: -webkit-linear-gradient(top, #a7cfdf 0%, #23538a 100%);
  background: linear-gradient(to bottom, #a7cfdf 0%, #23538a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a7cfdf', endColorstr='#23538a',GradientType=0 );
  transform: 0.2s;
}
#upload-file-final-container p {
  font-size: 16px;
  color: #737373;
  margin: 0;
}

.uploadType {
  padding: 0 5px;
  max-width: 300px;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: block;
}

.inputGroup {
  background-color: #131313;
  display: block;
  margin: 5px 0 10px 0;
  position: relative;
}
.inputGroup label {
  padding: 5px 0 4px 0;
  width: 100%;
  display: block;
  text-align: center;
  color: #3c454c;
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: color 200ms ease-in;
  overflow: hidden;
}
.inputGroup label:before {
  width: 10px;
  height: 10px;
  content: '';
  background-color: #3c6d45;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: -1;
}

.inputGroup input:checked ~ label {
  color: #fff;
}
.inputGroup input:checked ~ label:before {
  -webkit-transform: translate(-50%, -50%) scale3d(56, 56, 1);
  transform: translate(-50%, -50%) scale3d(56, 56, 1);
  opacity: 1;
}
.inputGroup input:checked ~ label:after {
  background-color: #2d4c30;
  border-color: #2d4c30;
}
.inputGroup input {
  width: 32px;
  height: 32px;
  order: 1;
  z-index: 2;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  visibility: hidden;
}
/* END Upload */

/* Single Media Item */
.single-media {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  color: #6d6d6d;
  padding: 0 20px;
}
.single-media .artwork {
  font-size: 330px;
  color: #232323;
  margin-top: -12px;
  margin-bottom: -10px;
}

.single-media .meta {
  flex-direction: row;
  margin: 0 0 20px 40px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
}

.single-media input[type='text'] {
  width: 100%;
  padding: 5px;
  font-size: 15px;
  margin-bottom: 10px;
}

.type-select select {
  font-size: 15px;
  width: 100px;
  margin: 0 0 5px 10px;
  height: 25px;
}

.type-select select:focus {
  outline: none;
}

.single-media input[type='radio'] {
  margin: 0 0 20px 10px;
}

.single-media .data {
  margin-left: 10px;
  color: #d2d2d2;
  font-weight: bold;
}

.single-media .green {
  color: #296e2f;
}

.single-media .red {
  color: #ad4646;
}

.single-media .owner {
  /* color: #6b946b; */
  /* margin-bottom: 34px; */
}

.single-media .title {
  font-size: 22px;
  color: white;
  font-weight: bold;
}

.single-media .artist {
  font-size: 18px;
  color: #ddd;
  font-weight: bold;
  margin-bottom: 20px;
}

.single-media .locked {
  background: #353535;
  color: #969696;
  padding: 6px 0 4px 0;
  text-align: center;
  text-shadow: 1px 1px 1px #000000;
  margin: 15px 0 20px 0;
}

.single-media .spacer {
  /* height: 39px; */
  margin-top: 15px;
  display: block;
}

.sin-play-wrap {
  padding: 30px 20px 0 20px;
}

.featuredPlaylists {
  width: 100%;
  padding-bottom: 50px;
}

.feat-pl-header {
  width: 100%;
  background: #0c0c0c;
  color: #696969;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  padding: 6px;
  border-top: 1px solid black;
  border-bottom: 1px solid #272727;
}

a.in-playlist {
  width: 100%;
  background: #1b1b1b;
  padding: 10px 50px;
  margin-bottom: 2px;
  display: flex;
  transition: 0.2s;
}

a.in-playlist:nth-child(even) {
  background: #212121;
}

a.in-playlist:hover {
  background: #2f2f2f;
}

.featuredPlaylists .title {
  color: white;
  font-weight: bold;
}

.featuredPlaylists .ctd {
  color: #828282;
  padding: 0 5px;
}

.featuredPlaylists ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.single-media .delete-btn {
  margin-left: 20px;
}

.blue-btn {
  background: #3f506c;
  color: #ffffff;
  box-shadow: none;
}

.blue-btn:hover {
  background: #426c9e;
}

.red-btn {
  background: #692222;
  color: #ffffff;
  box-shadow: none;
}

.red-btn:hover {
  background: #af2424;
}

.green-btn {
  background: #445a36;
  color: #ffffff;
  box-shadow: none;
}

.green-btn:hover {
  background: #5f804a;
}

/* TrackList */
.sort-display {
  padding: 10px 20px;
  background: #151515;
  color: #797979;
  font-size: 15px;
  text-align: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.sort-display select {
  font-size: 14px;
  margin-left: 5px;
}

.sort-display select:focus {
  outline: none;
}

.search {
  margin-left: 30px;
}

.searchBox[type='text'] {
  padding: 5px;
  width: 200px;
  margin-left: 5px;
}

.searchBox[type='text']:focus {
  outline: none;
}

ul.media-list {
  margin: 0;
  padding: 0 0 50px 0;
  display: block;
}

.many-list .playlist-item {
  padding: 8px 20px;
}

.playlist-item {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 1px;
  padding: 8px 10px;
  transition: 0.3s padding;
  font-family: arial, sans-serif;
  background: #1d1d1d;
  border-top: 1px solid #232323;
}

.playlist-item:nth-child(even) {
  background: #232323;
  border-top: 1px solid #2d2d2d;
}

.playlist-item .position {
  font-size: 36px;
  margin: -3px 0 -10px 0;
  color: #131313;
  /* text-shadow: 1px 1px 1px #2b2b2b; */
  width: 55px;
  text-align: center;
}

.playlist-item .show-warn {
  flex: 0 0 20px;
  font-size: 20px;
  padding: 7px 10px 0 0;
  color: orange;
}

.icon-static {
  flex: 1;
  flex: 0 0 60px;
  font-size: 30px;
  padding: 1px 10px 0 0;
  color: #8c8c8c;
  width: 60px;
  justify-content: center;
  text-align: center;
}

.playlist-item .track-play {
  flex: 1;
  flex: 0 0 30px;
  font-size: 30px;
  padding: 1px 10px 0 0;
  cursor: pointer;
  color: #4e4e4e;
  transition: 0.2s;
}

.playlist-item .jingle-play {
  flex: 1;
  flex: 0 0 30px;
  font-size: 30px;
  padding: 1px 14px 0 0;
  cursor: pointer;
  color: #4e4e4e;
  transition: 0.2s;
  margin-left: 5px;
}

.playlist-item .meta-bottom {
  font-size: 11px;
  margin-top: 2px;
  font-weight: bold;
}

.playlist-item .m-title {
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

.playlist-item .artist {
  font-size: 13px;
  color: #bdbdbd;
  margin-left: 5px;
}

.playlist-item .m-title:hover {
  text-decoration: underline;
}

.playlist-item .track-play:hover,
.playlist-item .jingle-play:hover {
  color: white;
}

.playlist-item .duration {
  color: #c7ab68;
  margin-right: 10px;
}

.playlist-item .filename {
  color: #8191b3;
  margin-right: 10px;
}

.playlist-item .age {
  color: #b56565;
}

.playlist-item .show-data {
  margin-left: 10px;
  color: #bca9d0;
}

.playlist-item .activated {
  color: #70ca70;
  margin-left: 10px;
}

.playlist-item .deactivated {
  color: #424242;
  margin-left: 10px;
}

.media-active {
  background: #49704e54 !important;
  /* padding-left: 45px !important; */
  margin: 10px 20px;
  border-top: none !important;
  border-radius: 10px;
}

/* Current Cue */
.cue-wrap {
  margin-bottom: 1px;
  display: block;
  box-sizing: border-box;
}

.cue-head {
  background: #2d2d2d;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  color: #bbbbbb;
  box-sizing: border-box;
  padding: 5px;
}

.current-cue {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: #1d1d1d;
  padding: 20px 20px;
}

.current-cue .meta {
  text-align: center;
  color: gray;
  font-size: 15px;
}

.current-cue .time-display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#c-media-current,
#c-media-total {
  background: #131313;
  color: #909090;
  font-weight: bold;
  padding: 2px 5px 1px 5px;
  font-size: 10px;
}

.current-cue .title {
  font-weight: bold;
  color: #6f99b9;
  margin-left: 5px;
}

.current-cue .artist {
  font-weight: bold;
  color: #6f99b9;
}

.current-cue .bar {
  width: 100%;
  background: #131313;
  height: 11px;
  display: block;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px #2f2f2f;
  padding: 1px;
}

#cc-progress {
  background: #3f5f77;
  height: 9px;
  display: block;
  width: 0%;
  transition: 0.2s;
  background: -moz-linear-gradient(
    top,
    #517591 1%,
    #687e8e 44%,
    #3f5f77 79%,
    #3f5f77 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #517591 1%,
    #687e8e 44%,
    #3f5f77 79%,
    #3f5f77 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #517591 1%,
    #687e8e 44%,
    #3f5f77 79%,
    #3f5f77 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.current-playlist-meta {
  width: 100%;
  display: block;
  box-sizing: border-box;
  text-align: center;
  background: #1d1d1d;
  padding: 10px 50px 15px 50px;
  margin-bottom: 1px;
}

.current-playlist-meta h4 {
  color: #68ad75;
  margin: 10px;
  font-size: 18px;
}

.current-playlist-meta p {
  margin: 5px;
  font-size: 14px;
}

/* Current Schedule Cue */
.current-schedule-cue {
  width: 100%;
  display: block;
  box-sizing: border-box;
  text-align: left;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.current-schedule-cue .c-top {
  display: flex;
  padding: 5px 20px 5px 5px;
  margin-bottom: 1px;
  border-bottom: 1px solid black;
  background: #252525;
}

.current-schedule-cue .c-bottom {
  display: flex;
  flex-direction: row;
}

.c-bottom .elapsed {
  border-left: 1px solid #2d2d2d;
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  width: 78px;
}

.c-bottom .heading {
  font-size: 9px;
  font-weight: bold;
  color: rgb(77, 77, 77);
  margin-bottom: 2px;
  text-align: center;
}

.c-bottom .heading2 {
  font-size: 9px;
  font-weight: bold;
  color: rgb(77, 77, 77);
  margin-bottom: 2px;
  text-align: left;
}

.c-bottom .cl-block {
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.c-bottom .clock {
  font-size: 24px;
  padding: 2px 5px 2px 5px;
}

.c-bottom .remaining {
  border-left: 1px solid #2d2d2d;
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  width: 78px;
}

.c-bottom .up-next {
  border-left: 1px solid #2d2d2d;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  align-content: left;
  text-align: left;
}

.c-bottom .up-next-txt {
  font-size: 11px;
  color: rgb(172, 172, 172);
}

#e-countdown,
#countdown {
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: bold;
  color: rgb(127, 152, 127);
}

#e-countdown {
  color: rgb(127, 152, 127);
}

#countdown {
  color: rgb(160, 121, 121);
}

.current-schedule-cue .onair {
  background: #3a3a3a;
  color: black;
  padding: 8px 8px 7px 8px;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  /* width: 60px; */
  margin-right: 10px;
}

.current-schedule-cue .meta {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 12px;
  margin-top: 2px;
}

.current-schedule-cue .top {
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.cd-margin {
  margin-left: 10px;
}
.cd-green {
  color: #5e8a60;
}

.cd-orange {
  color: #b48e47;
}

.cd-red {
  color: #d05959;
}

.bg-green {
  background: #4d714e !important;
}

.bg-orange {
  background: #8e6e32 !important;
}

.bg-red {
  background: #d05959 !important;
}

.current-schedule-cue p {
  margin: 5px;
  font-size: 14px;
}

/* alertify */
.alertify .ajs-header {
  background-color: #424242;
  font-size: 20px;
  color: rgb(163, 163, 163);
}
.alertify .ajs-body .ajs-content {
  padding: 30px 0 16px 0;
  color: #242424;
}
.alertify .ajs-footer .ajs-buttons.ajs-primary .ajs-button {
  margin: 4px;
  padding: 5px 10px 6px 10px;
  border: none;
  font-size: 14px;
  font-weight: bold;
}

.alertify .ajs-dialog {
  padding: 24px 24px 10px 24px;
}
.ajs-ok {
  background: #3b516b;
  color: white;
  cursor: pointer;
  transition: 0.2s;
  -webkit-transition: 0.2s;
}
.ajs-ok:hover {
  background: #426c9e;
}
.ajs-cancel {
  background: #bbbbbb;
  color: #2f2f2f;
  cursor: pointer;
  transition: 0.2s;
  -webkit-transition: 0.2s;
}
.ajs-commands {
  margin: -7px 20px 0 0 !important;
  right: 0;
}

.alertify .ajs-commands button.ajs-close {
  background-image: none;
  padding: 0;
  color: rgb(163, 163, 163);
}
button.ajs-close:after {
  font-family: 'Font Awesome\ 5 Free';
  content: '\f057';
  font-size: 20px;
}

button.ajs-close:hover {
  color: white !important;
}

/* Playlist Generation */
.generate {
  text-align: center;
  color: rgb(173, 173, 173);
}

.generate h2,
h3 {
  color: white;
}

.generate .info {
  max-width: 800px;
  min-width: 500px;
  margin: 0 auto;
  padding-bottom: 10px;
}

.generate .info p {
  text-align: left;
  margin: 8px 0;
}

.generate .title {
  padding: 5px;
  margin-right: 20px;
  width: 300px;
  font-size: 15px;
}

.generate .title:focus {
  outline: none;
}

.generate .bottom {
  margin: 20px;
}

.generate .save-btn {
  margin: 0;
  padding: 9px 10px 7px 10px;
  border: none;
  font-weight: normal;
  cursor: pointer;
  box-shadow: none;
  transition: 0.2s;
  font-size: 14px;
  background: #3b516b;
  font-weight: bold;
  color: white;
}

.generate .save-btn:hover {
  background: #426c9e;
}

.generate .restart-btn {
  margin: 0;
  padding: 9px 10px 7px 10px;
  border: none;
  font-weight: normal;
  cursor: pointer;
  box-shadow: none;
  transition: 0.2s;
  font-size: 14px;
  background: #2c3a21;
  font-weight: bold;
  color: white;
}

.generate .restart-btn:hover {
  background: #425f2b;
}

.generate .clear-btn:hover {
  background: white;
}

.generate .clear-btn {
  margin: 0;
  padding: 7px 10px 5px 10px;
  border: none;
  font-weight: normal;
  cursor: pointer;
  box-shadow: none;
  transition: 0.2s;
  font-size: 12px;
  background: #a3a3a3;
  color: rgb(22, 22, 22);
}

.generate .options {
  text-align: left;
  margin: 0 auto 30px auto;
  line-height: 27px;
  width: 470px;
  padding: 20px 20px 10px 20px;
  background: #1d1d1d;
}

.generate .options select {
  font-size: 14px;
  margin: 0 0 10px 10px;
}

.generate .options select:focus {
  outline: none;
}

.box-icon {
  font-size: 60px;
  margin-bottom: 10px;
  color: #000000;
  text-shadow: 1px 1px 1px #292929;
  padding-top: 15px;
}

.highlight {
  color: white;
  font-weight: bold;
}

.dim {
  color: #545454;
}

.playlist {
  border-top: 1px solid #333333;
  margin-top: 30px;
  padding-top: 10px;
}

/* Icecast */
.icecast-wrap {
  text-align: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
}

.icecast-wrap p,
.icecast-wrap h2 {
  text-align: center;
}

.help {
  color: #5e84ce;
  font-size: 18px;
}

.icecast-wrap ul {
  display: flex;
  font-size: 1.2em;
  color: #5f5f5f;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  line-height: 0.9em;
  margin: 0;
  padding: 0;
}

.icecast-wrap li {
  background: #0e0e0e;
  padding: 20px 10px;
  /* flex: 0 0 250px; */
  font-size: 12px;
  width: 49%;
  margin: 10px 0;
  text-align: center;
  color: #484848;
  box-shadow: 1px 1px 1px #171717;
}

.icecast-wrap li .hlt {
  color: #b7b7b7;
}

.icecast-wrap .icon {
  font-size: 60px;
  color: #333;
  padding-bottom: 10px;
}

/* Playlist History */
.pl-history-item {
  width: 100%;
  background: #1b1b1b;
  padding: 10px 20px;
  border-top: 1px solid #292929;
  border-bottom: 1px solid #151515;
  display: flex;
}

.pl-history-item:nth-child(even) {
  background: #212121;
}

.pl-history-item .meta {
  display: flex;
  line-height: 24px;
  margin: 3px 0;
}

.pl-history-item .info {
  display: flex;
}

.pl-history-item .time {
  font-size: 13px;
  color: #896d8a;
}

.pl-history-item .title {
  font-size: 16px;
  margin-right: 10px;
  color: white;
  font-weight: bold;
}

.pl-history-item a:hover {
  text-decoration: underline;
}

.pl-history-item .artist {
  font-size: 16px;
}

.pl-history-item .duration {
  font-size: 13px;
  margin-right: 20px;
  color: #c5c28e;
}

.pl-history-item .file {
  font-size: 13px;
  color: #83a0ce;
  margin-right: 20px;
}

.pl-history-item .age {
  font-size: 13px;
  color: #bb5757;
}

.pl-history-item .small-icon {
  width: 25px;
}

.pl-history-item .position {
  font-size: 76px;
  margin: -12px 20px -20px 0;
  color: #151515;
  text-shadow: 1px 1px 1px #2b2b2b;
}

/* Playout History */
.history {
  box-sizing: border-box;
}

.history-list {
  margin-bottom: 30px;
}

.history input[type='text'] {
  padding: 5px 5px 3px 5px;
  width: 110px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 13px;
  font-family: Arial;
}

.history-sort {
  display: flex;
  padding: 10px 20px;
  background: #151515;
  color: #797979;
  font-size: 15px;
  align-items: center;
  border-top: 1px solid black;
}

.history-sort span {
  margin-right: 10px;
}

.history input[type='text']:focus {
  outline: none;
}

.btn-history {
  font-size: 12px;
  padding: 6px 10px;
  background: #416341;
  color: white;
}

.btn-history:hover {
  background: #608a60;
  color: white;
}

.react-datepicker__close-icon::after {
  right: 8px !important;
  top: 7px !important;
  background-color: #924c4c !important;
  font-weight: bold;
}

.history-list table {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.history-list .hdr {
  background: #383838 !important;
  color: white;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
}

.history-list tr {
  background: #212121;
}

.history-list tr:nth-child(odd) {
  background: #171717;
}

.history-list td {
  padding: 5px 10px;
}

.history-list .type {
  text-align: center;
  width: 55px;
}

.history-list .first-cell {
  padding-left: 20px;
  width: 170px;
}

.history-list a {
  font-weight: bold;
}

.history-list a:hover {
  text-decoration: underline;
}

.history-list .last-cell {
  padding-right: 20px;
}

.history-item:nth-child(even) {
  background: #212121;
}

.home-wrap {
  /* padding: 0 50px; */
  display: flex;
  transform: 0.2s;
}

.home-wrap h2 {
  text-align: center;
  color: #ababab;
  font-size: 17px;
  margin: 5px 0 10px 0;
}

.home-wrap ul {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: 0;
  list-style: none;
}

.home-wrap .big-icon {
  font-size: 40px;
  color: #afafaf;
  text-shadow: none;
  padding: 0;
}

.home-activity {
  display: block;
  background: #101010;
  padding: 20px 20px 50px 20px;
}

.h-right {
  display: flex;
  margin-left: auto;
  flex-grow: 1;
}

.home-users {
  display: block;
  padding: 20px 10px 10px 10px;
  border-left: 1px solid #070707;
  /* box-shadow: inset 0 10px 10px -10px black; */
  width: 100%;
}

.activity-item {
  display: flex;
  font-size: 14px;
  background: #1d1d1d;
  padding: 6px 20px;
  margin-bottom: 5px;
  border-top: 1px solid #2b2b2b;
  border-radius: 10px;
}

.activity-item:hover,
.activity-item:nth-child(even):hover {
  background: rgb(48, 48, 48);
}

.activity-item:nth-child(even) {
  background: #272727;
}

.activity-item .icon-ul {
  color: #777777;
  margin-right: 10px;
  font-size: 18px;
  margin-bottom: -3px;
  text-align: center;
  width: 20px;
}

.activity-item .msg {
  margin-top: 1px;
}

.activity-item a {
  font-weight: bold;
}

.activity-item a:hover {
  text-decoration: underline;
}

.activity-item .time_ago,
.process-item .time_ago {
  font-size: 12px;
  margin-left: 10px;
}

.process-item .msg {
  margin-top: 1px;
}

.hl {
  font-weight: bold;
  color: white;
}
.home-processing {
  text-align: center;
  display: block;
  background: #0c0c0c;
  padding: 20px;
  border-bottom: 1px solid #000000;
}
.process-item {
  display: flex;
  font-size: 14px;
  background: #1d1d1d;
  padding: 6px 20px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.process-item .icon-ul {
  color: white;
  margin-right: 10px;
  font-size: 18px;
  margin-bottom: -3px;
}
.process-item.current {
  background: #244024 !important;
}

.process-item .msg {
  text-align: left;
}

.onlineUserList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  justify-content: center;
}

.user-item {
  background: #0a0a0a;
  padding: 5px 5px;
  min-width: 110px;
  border-radius: 6px;
  font-weight: bold;
  color: white;
  font-size: 11px;
  margin: 0 5px 10px 5px;
  text-align: center;
  box-shadow: 1px 1px 1px #191919;
  transition: 0.2s;
}

.user-item:hover {
  background: #222222;
  box-shadow: 1px 1px 1px #0e0e0e;
}

.user-item img {
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 3px;
}

/* Create Playlist */
.create-pl-wrap {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  margin-bottom: 20px;
}
.create-medialist {
  width: 49%;
  min-height: 50vh;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid white;
  /* padding: 5px; */
}
.create-playlist {
  width: 49%;
  min-height: 50vh;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid white;
  /* padding: 5px; */
}
.cpl-header {
  padding: 20px 20px 0 20px;
  display: flex;
  justify-content: space-between;
}
.cpl-header .left {
  width: 49%;
}
.cpl-header .right {
  width: 49%;
}
.cpl-header .total-duration {
  text-align: center;
  background: #344c3a;
  padding: 5px;
  font-size: 15px;
  color: white;
}
.cpl-header p {
  margin: 0 0 10px 0;
  font-size: 14px;
}
.cpl-header .icon {
  width: 20px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
  color: #ded656;
}
.cpl-header .save {
  display: flex;
  margin-bottom: 30px;
}
.cpl-header input {
  width: 100%;
  padding: 5px;
  font-size: 15px;
  margin-right: 30px;
}
.cpl-header input:focus {
  outline: none;
}
.cpl-header .btn-save {
  background: #3b516b;
  color: #ececec;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 14px;
  box-shadow: none;
  border: none;
  cursor: pointer;
}
.cpl-header .btn-save:hover {
  background: #426c9e;
}
.list-item {
  display: flex;
  /* justify-content: space-around; */
}
.cpl {
  text-align: center;
  margin: 0;
  background: #0c0c0c;
  font-size: 16px;
  padding: 10px 0;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #1b1b1b;
}
.list-item .position {
  color: black;
  font-size: 30px;
  margin-right: 10px;
  width: 30px;
  text-align: left;
  letter-spacing: -1px;
  margin-bottom: -5px;
  text-shadow: 1px 1px 1px #2b2b2b;
}
.list-item .type {
  color: #484848;
  font-size: 30px;
  margin-right: 10px;
  width: 33px;
  text-align: center;
  margin-bottom: -5px;
}
.list-item .title {
  font-size: 14px;
  font-weight: bold;
  color: white;
}
.list-item .artist {
  color: #8ca6d0;
}
.list-item .stamps {
  margin-left: auto;
  text-align: right;
}
.list-item .duration {
  color: orange;
}
.list-item .time {
  font-size: 11px;
  margin-top: 5px;
}
/* End of Create Playlist */

/* My Playlists */
.playlists-wrap {
  /* padding: 30px 0; */
  display: flex;
  flex-direction: column;
  border-top: 1px solid black;
}

.playlists-wrap .header {
  font-size: 11px !important;
  font-weight: bold;
  line-height: 0;
  border-bottom: none;
  background: #292929 !important;
}

.playlists-wrap .item {
  background: #1d1d1d;
  border-top: 1px solid #232323;
  margin-bottom: 1px;
  padding: 10px 20px;
  transition: 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
}

.playlists-wrap .hdr {
  color: #717171 !important;
}

.playlists-wrap .item:hover {
  background: #252525;
}

.playlists-wrap h2 {
  text-align: center;
  margin: 0 0 0 0;
  background: #191919;
  padding: 10px 20px;
  font-size: 15px;
  border-bottom: 1px solid black;
  font-family: Roboto;
}

.playlists-wrap .one {
  flex: 1;
}
.playlists-wrap .two {
  text-align: left;
  flex: 1;
  color: #986da5;
}
.playlists-wrap .three {
  text-align: left;
  flex: 1;
  color: #6889b5;
}
.playlists-wrap .four {
  text-align: left;
  flex: 1;
  color: #7ca27a;
}
.playlists-wrap .five {
  text-align: right;
  color: orange;
  flex: 1;
}

/* My Media */

.umedia-item {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 1px;
  padding: 8px 20px;
  transition: 0.3s padding;
  font-family: arial, sans-serif;
  background: #1d1d1d;
  border-top: 1px solid #232323;
}

.umedia-item:nth-child(even) {
  background: #232323;
  border-top: 1px solid #2d2d2d;
}

.umedia-item .icon-static {
  flex: 1;
  flex: 0 0 50px;
  font-size: 30px;
  padding: 1px 10px 0 0;
  color: #585858;
  width: 40px;
  justify-content: center;
  text-align: center;
}

.umedia-item .track-play {
  flex: 1;
  flex: 0 0 50px;
  font-size: 30px;
  padding: 1px 10px 0 0;
  cursor: pointer;
  color: #4e4e4e;
  transition: 0.2s;
  justify-content: center;
  text-align: center;
}

.umedia-item .meta-bottom {
  font-size: 11px;
  margin-top: 2px;
  font-weight: bold;
}

.umedia-item .m-title {
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

.umedia-item .artist {
  font-size: 13px;
  color: #bdbdbd;
  margin-left: 5px;
}

.umedia-item .m-title:hover {
  text-decoration: underline;
}

.umedia-item .track-play:hover {
  color: white;
}

.umedia-item .duration {
  color: #c7ab68;
  margin-right: 10px;
}

.umedia-item .filename {
  color: #8191b3;
  margin-right: 10px;
}

.umedia-item .age {
  color: #b56565;
}

.umedia-item .meta {
  flex-grow: 1;
}

.umedia-item .um-active {
  font-size: 25px;
  margin: 4px 0 0 auto;
  width: 50px;
}

.umedia-item .um-pl {
  font-size: 25px;
  margin: 4px 0 0 auto;
  width: 30px;
}

.umedia-item .activated {
  color: #70ca70;
}

.umedia-item .deactivated {
  color: #424242;
}

/* Single Playlist */
.single-media .restart {
  margin: 20px 0;
}

.pl-single {
  padding: 20px 20px 0 20px;
}

.pl-icon {
  font-size: 270px;
  color: #232323;
  margin-top: -12px;
}

.editing {
  text-align: center;
  width: 100%;
  padding: 20px 0 0 0;
}

.edit-pl-wrap .total-duration {
  padding: 9px 8px 8px 8px;
}
.edit-pl-wrap .left {
  display: flex;
}
.edit-pl-wrap .title {
  width: 100%;
  margin-right: 20px;
}

.edit-pl-wrap .blue-btn {
  min-width: 100px;
}

.edit-pl-wrap h3 {
  margin: 20px 0 0 0;
  color: rgb(192, 192, 192);
  font-weight: normal;
}

.edit-pl-wrap .create-pl-wrap {
  padding: 20px 50px;
}

.pl-single .locked {
  color: #a7a7a7;
  font-size: 15px;
}

.pl-single .title {
  font-size: 30px;
  margin-bottom: 10px;
}

.pl-single .owner {
  margin-bottom: 17px;
}

.mrt20 {
  margin-right: 20px;
}

.pl-list {
  padding-bottom: 50px;
}
.pl-list ul {
  padding: 0;
}
.pl-list .position {
  font-size: 60px;
  margin: -10px 20px -10px 0;
}

.pl-list .title {
  font-size: 17px;
}

.grey {
  color: grey;
}

/* Current Playlist Show */
.cd-progress-wrap {
  width: 100%;
  background: black;
  height: 7px;
  display: block;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px #444343;
  border: 1px solid black;
}

#cd-progress {
  background: #3b3b3b;
  width: 100%;
  height: 5px;
  display: block;
  transition: 0.2s;
}

.current-playlist-meta p {
  font-size: 12px;
}

/* Scheduling */
.schedule-wrap {
  padding: 20px 19px 30px 19px;
  box-sizing: border-box;
}
.schedule {
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.sch-table {
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
}

.schedule .banner {
  text-align: center;
  background: #775a3d;
  padding: 4px 0 3px 0;
  border: 1px solid #131313;
  font-size: 14px;
  color: white;
  font-weight: bold;
}

.sch-table .col1,
.sch-table .col2,
.sch-table .col3,
.sch-table .col4,
.sch-table .col5,
.sch-table .col6,
.sch-table .col7 {
  border: 1px solid #131313;
  flex-direction: column;
  min-width: 14.28%;
  box-sizing: border-box;
  background: #bfbfbf;
}

.sch-table .col-hr {
  border-bottom: 2px solid #131313;
  padding: 5px;
  text-align: center;
  background: #616161;
  color: white;
  font-size: 14px;
  margin-bottom: 2px;
  font-weight: bold;
}

.sch-table .col-cell {
  background: black;
  color: white;
  border-bottom: 1px solid #bfbfbf;
  border-left: 2px solid #bfbfbf;
  border-right: 2px solid #bfbfbf;
  padding: 2px 5px;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
}

.sch-table .col-cell:hover {
  background: rgb(62, 128, 62);
}

.schedule-wrap .legend {
  background: #e4e4e4;
  border-left: 1px solid #131313;
  border-right: 1px solid #131313;
  color: #242424;
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
}

.legend .l-info {
  margin: 2px 10px 0 10px;
}

.legend .l-shft {
  background: green;
  color: white;
  padding: 2px 5px;
  margin-left: 10px;
}

.legend .l-plist {
  background: #abad49;
  color: white;
  padding: 2px 5px;
  margin-left: 10px;
}

.legend .l-week {
  background: black;
  color: white;
  padding: 2px 5px;
  margin-right: 10px;
}

.legend .l-biwk {
  background: rgb(51, 49, 148);
  color: white;
  padding: 2px 5px;
  margin-right: 10px;
}

.legend .l-mont {
  background: rgb(179, 41, 41);
  color: white;
  padding: 2px 5px;
  margin-right: 10px;
}

.legend .l-live {
  background: #b5682e;
  color: white;
  padding: 2px 5px;
}

.schedule .active {
  background: green;
}

.schedule .b-live {
  font-size: 7px;
  color: #b5682e;
  margin-right: 5px;
}

.schedule .use-playlist {
  font-size: 8px;
  color: #dde04a;
  margin-right: 5px;
}

.sch-table .active {
  background: green !important;
}

.sch-table .weekly {
  background: black;
}

.sch-table .bi-weekly {
  background: rgb(51, 49, 148);
}

.sch-table .monthly {
  background: rgb(179, 41, 41);
}

/* Modal */
.modal {
  opacity: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.25s ease;
  z-index: 1;
}

.modal__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.modal__inner {
  transition: top 0.25s ease;
  position: absolute;
  top: -10%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 60%;
  margin: auto;
  overflow: auto;
  background: rgb(238, 238, 238);
  padding: 1em 2em;
  height: 85%;
  color: #151515;
  border-radius: 10px;
}

.modal__inner .wrapper {
  display: flex;
  flex-direction: column;
}

.modal__inner p {
  font-size: 12px;
  color: rgb(114, 114, 114);
  text-align: center;
}

.modal__inner .row {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  align-content: flex-start;
}

.modal__inner .row-center {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  justify-content: center;
}

.rht .pointer {
  cursor: pointer !important;
}

.modal__inner .avatar {
  text-align: center;
}

.modal__inner .ghost {
  text-align: center;
  font-size: 13px;
  margin-bottom: 5px;
  color: #ff6200;
}

.modal__inner .lft {
  width: 40%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  background: #d6d6d6;
  color: rgb(112, 112, 112);
  font-weight: bold;
  font-size: 13px;
  padding-left: 10px;
  box-sizing: border-box;
}

.modal__inner .lft2 {
  width: 40%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 13px;
  padding-left: 10px;
  box-sizing: border-box;
}

.modal__inner .rht {
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.modal__inner .rht2 {
  width: 60%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 4px 10px 2px 10px;
  border: 1px solid #d6d6d6;
  font-size: 15px;
  font-weight: bold;
}

.modal__inner .rht3 {
  width: 60%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 0;
  font-size: 15px;
  font-weight: bold;
  flex-wrap: wrap;
}

.modal__inner a {
  color: #131313;
  text-decoration: none;
}

.modal__inner a:hover {
  text-decoration: underline;
}

.red-tint {
  background: #f5b2b2 !important;
  border: 1px solid #f5b2b2 !important;
}

.red-box {
  border: 1px solid #f5b2b2 !important;
}

.blue-tint {
  background: #c1cff3 !important;
  border: 1px solid #c1cff3 !important;
}

.blue-box {
  border: 1px solid #c1cff3 !important;
}

.orange-tint {
  background: #f5d19c !important;
  border: 1px solid #f5d19c !important;
}

.orange-box {
  border: 1px solid #f5d19c !important;
}

.modal__inner input {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #d6d6d6;
  width: 100%;
}

.modal__inner input:focus {
  outline: none;
}

.modal__inner select {
  font-size: 14px;
  height: 28px;
  padding: 5px;
  border: 1px solid #d6d6d6;
  /* -webkit-border-radius: 0; */
  border: 0;
  outline: 1px solid #ccc;
  outline-offset: -1px;
  /* -webkit-appearance: none;
  -webkit-border-radius: 0px;
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
  background-position: 100% 50%;
  background-repeat: no-repeat; */
}

.modal__inner .save-btn {
  margin: 0;
  padding: 5px 20px 5px 20px;
  border: none;
  font-weight: normal;
  cursor: pointer;
  box-shadow: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-size: 14px;
  background: #3b516b;
  font-weight: bold;
  color: white;
}

.modal__inner .save-btn:hover {
  background: #426c9e;
}

.modal__inner .cancel-btn {
  margin: 0 0 0 20px;
  padding: 5px 10px 5px 10px;
  border: 1px solid #afafaf;
  font-weight: normal;
  cursor: pointer;
  box-shadow: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-size: 14px;
  background: #ccc;
  font-weight: bold;
  color: rgb(103, 103, 103);
}

.modal__inner .cancel-btn:hover {
  background: #bbbbbb;
  color: #333;
}

.modal__inner .delete-btn {
  margin: 0 0 0 20px;
  padding: 5px 10px 5px 10px;
  border: none;
  font-weight: normal;
  cursor: pointer;
  box-shadow: none;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-size: 14px;
  background: #c33939;
  font-weight: bold;
  color: white;
}

.modal__inner .delete-btn:hover {
  background: #d85c5c;
  color: white;
}

.modal__inner h2 {
  margin: 10px 0 20px 0;
  font-size: 20px !important;
  border: none !important;
}

.modal__inner .singleDj {
  background: green;
  color: white;
  padding: 3px 5px 2px 5px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin: 2px 5px 2px 0;
  transition: All 0.2s;
}

.modal__inner .singleDj:hover {
  background: rgb(150, 48, 48);
}

.modal__inner .play-warn {
  text-align: center;
  width: 100%;
  font-size: 14px;
  color: #ff6200;
}

.m-errors {
  background: #9c3c3c;
  color: white;
  padding: 6px 10px;
  font-size: 15px;
  text-align: center;
}
/*
.modal-state {
  display: none;
}

.modal-state:checked + .modal {
  opacity: 1;
  visibility: visible;
}

.modal-state:checked + .modal .modal__inner {
  top: 0;
}

.modal__inner {
  transition: top 0.25s ease;
  position: absolute;
  top: -20%;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  margin: auto;
  overflow: auto;
  background: #fff;
  border-radius: 5px;
  padding: 1em 2em;
  height: 50%;
}

.modal__close {
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.1em;
  height: 1.1em;
  cursor: pointer;
}

.modal__close:after,
.modal__close:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 1.5em;
  background: #ccc;
  display: block;
  transform: rotate(45deg);
  left: 50%;
  margin: -3px 0 0 -1px;
  top: 0;
}

.modal__close:hover:after,
.modal__close:hover:before {
  background: #aaa;
}

.modal__close:before {
  transform: rotate(-45deg);
}*/

@media screen and (max-width: 768px) {
  .modal__inner {
    width: 90%;
    height: 90%;
    box-sizing: border-box;
  }
}

/* DJ Management */
.djm-wrap h2 {
  text-align: center;
  border-top: 1px solid black;
  padding: 10px;
  margin: 0;
  font-size: 15px;
}

/* DJs List */
.djslist {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
}

.djslist .single-dj {
  width: 100%;
  display: flex;
  color: white;
  padding: 10px 20px;
  justify-content: space-between;
  font-size: 13px;
  transition: 0.2s;
  margin-top: 2px;
}

.djslist .single-dj:hover {
  background: rgb(165, 165, 165);
  color: black;
  cursor: pointer;
}

.djslist .weekly {
  background: #1f1f1f;
}

.djslist .bi-weekly {
  background: rgb(46, 45, 76);
}

.djslist .monthly {
  background: rgb(80, 49, 49);
}

.djslist .deactivated {
  opacity: 0.2;
}

.djslist .shifts {
  display: flex;
  width: 12.5%;
}

.shifts .shift-on {
  background: #188218;
  color: white;
  padding: 3px 5px 0px 5px;
  font-weight: bold;
  font-size: 10px;
  margin-right: 3px;
}

.shifts .shift-off {
  background: black;
  color: #525252;
  padding: 3px 5px 0px 5px;
  font-weight: bold;
  font-size: 10px;
  margin-right: 3px;
}

.s-dj-header {
  background: rgb(65, 65, 65);
  display: flex;
  font-weight: bold;
  padding: 5px 20px 3px 20px;
  font-size: 12px;
  color: white;
  border-top: 1px solid #565656;
}

.s-dj-header .name {
  width: 12.5%;
}

.s-dj-header .show {
  width: 27.5%;
}

.s-dj-header .day {
  width: 5.5%;
}

.s-dj-header .time {
  width: 12.5%;
}

.s-dj-header .shifts {
  width: 12.5%;
}

.s-dj-header .password {
  width: 12.5%;
}

.s-dj-header .live {
  width: 4.5%;
}

.single-dj .name {
  font-weight: bold;
  width: 12.5%;
}
.single-dj .show {
  width: 27.5%;
}
.single-dj .day {
  width: 5.5%;
}
.single-dj .time {
  width: 12.5%;
}
.single-dj .password {
  width: 12.5%;
}
.single-dj .live {
  width: 4.5%;
}
.single-dj .isActive {
  /* width: 4.5%; */
}

.add-dj {
  background: #1d1d1d;
  box-sizing: border-box;
  padding: 20px;
  margin: 0px;
  text-align: center;
  color: white;
  border-top: 1px solid #060606;
  border-bottom: 1px solid black;
}

/* Admin Manange Media Lists */
.mediaList {
  padding: 0;
  margin-bottom: 30px;
}

.mm-header {
  background: #272727;
  padding: 10px 20px;
  border-top: 1px solid #353535;
  display: flex;
  justify-content: space-around;
}

.mediaList table {
  width: 100%;
  text-align: left;
  border-spacing: 0;
}

.mediaList th {
  background: rgb(58, 58, 58);
  padding: 5px 20px;
  font-size: 11px;
  color: #d4d4d4;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.mediaList tr {
  background: rgb(31, 31, 31);
  transition: 0.2s;
  color: rgb(182, 182, 182);
}

.mediaList tr:nth-child(even) {
  background: rgb(37, 37, 37);
}

.mediaList td {
  padding: 5px 20px;
  font-size: 14px;
  border-bottom: 1px solid #080808;
  border-top: 1px solid #353535;
}

.mediaList td.last,
.mediaList th.last {
  text-align: center;
}

.mediaList .deactivated {
  color: #121212;
}

.mediaList .clickable {
  cursor: pointer;
}

.mediaList .clickable:hover {
  color: white;
}

.mediaList .red {
  color: rgb(192, 67, 67);
}

.mediaList .orange {
  color: rgb(192, 152, 67);
}

.mediaList .green {
  color: rgb(81, 161, 98);
}

.mediaList tr.admin {
  background: rgb(39, 66, 35);
}

.mediaList tr:hover {
  background: rgb(51, 51, 51);
}

.mediaList a {
  font-weight: bold;
  color: #e4e4e4;
}

.mediaList a:hover {
  text-decoration: underline;
}

.mediaList .t-bot {
  text-align: center;
  padding: 20px 0;
  color: #5d5d5d;
}

/* Users List */
.usersList {
  padding: 0;
}
.usersList table {
  width: 100%;
  text-align: left;
}

.usersList th {
  background: rgb(77, 77, 77);
  padding: 5px 20px;
  font-size: 11px;
  color: white;
}

.usersList tr {
  background: rgb(44, 44, 44);
  cursor: pointer;
  transition: 0.2s;
  color: white;
}

.usersList td {
  padding: 5px 20px;
  font-size: 15px;
}

.usersList td.last,
.usersList th.last {
  text-align: center;
}

.usersList tr.admin {
  background: rgb(39, 66, 35);
}

.usersList tr:hover {
  background: white;
  color: #242424;
}

.usersList .ghost {
  background: #afafaf !important;
  color: #6f6f6f;
}

.tb-avatar {
  width: 25px;
  height: 25px;
  padding: 2px 0 0 0 !important;
  text-align: center;
}

/* .tb-avatar img {
  width: 100%;
} */

/* Admin Icecast Stats */
.flex-table {
  width: 100%;
  display: flex;
  padding: 0 20px;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-table .header {
  background: #353535;
  font-size: 12px;
  color: white;
  padding: 10px 0 8px 0;
  justify-content: center;
  font-weight: bold;
  line-height: 1em;
  border-bottom: 0;
}

.flex-table .footer {
  background: #353535;
  padding: 10px 0 8px 0;
  line-height: 1em;
  height: 30px;
  margin-bottom: 50px;
  border: none;
}

.flex-table .row {
  display: flex;
  flex-direction: row;
}

.flex-table .cell-1 {
  background: #353535;
  font-size: 10px;
  color: #9a9a9a;
  padding: 8px 8px 6px 10px;
  font-weight: bold;
  width: 140px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-top: 1px solid #131313;
}

.flex-table .cell-1b {
  background: #353535;
  font-size: 10px;
  color: #9a9a9a;
  padding: 8px 8px 6px 10px;
  font-weight: bold;
  width: 140px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-top: 1px solid #131313;
  border-bottom: 1px solid #131313;
}

.flex-table .cell-2 {
  font-size: 13px;
  color: white;
  padding: 8px 8px 6px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  border-bottom: 1px solid #353535;
  border-right: 10px solid #353535;
}

.flex-table .cell-2b {
  font-size: 13px;
  color: white;
  padding: 8px 8px 6px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  border-right: 10px solid #353535;
}

/* Butters */
.butters {
  margin: 50px auto;
  display: block;
  width: 280px;
}

.butters .hair {
  margin-left: -135px;
  margin-top: -10px;
  position: absolute;
  display: block;
  color: #77682d;
  width: 550px;
  height: 20px;
  z-index: 3;
  border-right: 100px solid transparent;
  border-bottom: 70px solid #77682d;
  border-left: 100px solid transparent;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);

  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -ms-transform: scale(0.4);
  -o-transform: scale(0.4);
}
.butters .hair:before {
  border-bottom: 50px solid #77682d;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  position: absolute;
  height: 10px;
  width: 350px;
  top: 30px;
  left: -40px;
  display: block;
  content: '';
  -webkit-transform: rotate(-35deg);
  -moz-transform: rotate(-35deg);
  -ms-transform: rotate(-35deg);
  -o-transform: rotate(-35deg);
}
.butters .hair:after {
  position: absolute;
  display: block;
  color: #77682d;
  top: 0px;
  left: -40px;
  width: 300px;
  height: 20px;
  border-right: 100px solid transparent;
  border-bottom: 70px solid #77682d;
  border-left: 100px solid transparent;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  -o-transform: rotate(30deg);
  content: '';
}
.butters .head {
  position: absolute;
  width: 250px;
  height: 250px;
  background: #e6d6b6;
  z-index: 2;
  margin-left: 20px;
  margin-top: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.butters .eyebrow {
  position: absolute;
  margin-left: 65px;
  margin-top: 85px;
  width: 30px;
  height: 3px;
  background: #000;
  z-index: 4;

  -webkit-transform: rotate(-25deg);
  -moz-transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  -o-transform: rotate(-25deg);
  transform: rotate(-25deg);

  -webkit-animation: eyebrow_raise 8s infinite step-start 0s;
  -moz-animation: eyebrow_raise 8s infinite step-start 0s;
  -o-animation: eyebrow_raise 8s infinite step-start 0s;
  -ms-animation: eyebrow_raise 8s infinite step-start 0s;
  animation: eyebrow_raise 8s infinite step-start 0s;
}

@-webkit-keyframes eyebrow_raise {
  0% {
    margin-top: 85px;
    margin-left: 65px;
  }
  20% {
    margin-top: 80px;
    margin-left: 80px;
  }
  8% {
    margin-top: 85px;
    margin-left: 65px;
  }
}
.butters .eyebrow:after {
  content: '';
  position: absolute;
  margin-left: 75px;
  margin-top: 35px;
  width: 30px;
  height: 3px;
  background: #000;
  z-index: 4;

  -webkit-transform: rotate(50deg);
  -moz-transform: rotate(50deg);
  -ms-transform: rotate(50deg);
  -o-transform: rotate(50deg);
  transform: rotate(50deg);

  -webkit-animation: eyebrow_raise_after 8s infinite step-start 0s;
  -moz-animation: eyebrow_raise_after 8s infinite step-start 0s;
  -o-animation: eyebrow_raise_after 8s infinite step-start 0s;
  -ms-animation: eyebrow_raise_after 8s infinite step-start 0s;
  animation: eyebrow_raise_after 8s infinite step-start 0s;
}

@-webkit-keyframes eyebrow_raise_after {
  0% {
    margin-top: 35px;
    margin-left: 75px;
  }
  20% {
    margin-top: 30px;
    margin-left: 55px;
  }
  8% {
    margin-top: 35px;
    margin-left: 75px;
  }
}

.butters .eye {
  position: absolute;
  top: 90px;
  left: 60px;
  width: 60px;
  height: 80px;
  background-color: #fff;
  z-index: 3;

  -webkit-border-bottom-right-radius: 5px 5px;
  -moz-border-bottom-right-radius: 5px 5px;
  -ms-border-bottom-right-radius: 5px 5px;
  -o-border-bottom-right-radius: 5px 5px;
  border-bottom-right-radius: 5px 5px;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;

  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  transform: rotate(25deg);
}
.butters .eye:after {
  content: '';
  position: absolute;
  display: block;
  top: -25px;
  right: -62px;
  width: 60px;
  height: 80px;

  background-color: #fff;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;

  -webkit-transform: rotate(-50deg);
  -moz-transform: rotate(-50deg);
  -ms-transform: rotate(-50deg);
  -o-transform: rotate(-50deg);
  transform: rotate(-50deg);
}
.butters .iris {
  position: absolute;
  margin-top: 30px;
  right: 10px;
  width: 7px;
  height: 7px;
  background: #000;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;

  -webkit-animation: iris_down 6s infinite step-start 0s;
  -moz-animation: iris_down 6s infinite step-start 0s;
  -o-animation: iris_down 6s infinite step-start 0s;
  -ms-animation: iris_down 6s infinite step-start 0s;
  animation: iris_down 6s infinite step-start 0s;
}
.butters .iris:after {
  content: '';
  position: absolute;
  margin-top: -10px;
  left: 25px;
  width: 7px;
  height: 7px;
  background: #000;
  z-index: 1;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

@-webkit-keyframes iris_down {
  30% {
    margin-top: 5px;
  }
  30% {
    margin-top: 35px;
  }
  30% {
    margin-top: 35px;
  }
}

.butters .closed_eye {
  position: absolute;
  top: 90px;
  left: 60px;
  width: 60px;
  height: 80px;
  background-color: #ffedcb;
  border: 1px solid #eed6a8;
  z-index: 3;
  opacity: 0;

  -webkit-border-bottom-right-radius: 5px 5px;
  -moz-border-bottom-right-radius: 5px 5px;
  -ms-border-bottom-right-radius: 5px 5px;
  -o-border-bottom-right-radius: 5px 5px;
  border-bottom-right-radius: 5px 5px;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;

  -webkit-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -o-transform: rotate(25deg);
  transform: rotate(25deg);

  -webkit-animation: close_eyes 3s infinite step-start 0s;
  -moz-animation: close_eyes 3s infinite step-start 0s;
  -o-animation: close_eyes 3s infinite step-start 0s;
  -ms-animation: close_eyes 3s infinite step-start 0s;
  animation: close_eyes 3s infinite step-start 0s;
}
@-webkit-keyframes close_eyes {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  8% {
    opacity: 0;
  }
}
.butters .closed_eye:after {
  content: '';
  position: absolute;
  display: block;
  top: -25px;
  right: -62px;
  width: 60px;
  height: 80px;
  background-color: #ffedcb;
  border: 1px solid #eed6a8;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;

  -webkit-transform: rotate(-50deg);
  -moz-transform: rotate(-50deg);
  -ms-transform: rotate(-50deg);
  -o-transform: rotate(-50deg);
  transform: rotate(-50deg);

  -webkit-animation: close_eyes 3s infinite step-start 0s;
  -moz-animation: close_eyes 3s infinite step-start 0s;
  -o-animation: close_eyes 3s infinite step-start 0s;
  -ms-animation: close_eyes 3s infinite step-start 0s;
  animation: close_eyes 3s infinite step-start 0s;
}
.butters .closed_eyelid {
  position: absolute;
  top: 30px;
  left: 0px;
  border-top: 1px solid #eed6a8;
  width: 60px;
  height: 50px;
  border-radius: 100%;
}
.butters .closed_eyelid:after {
  content: '';
  position: absolute;
  top: -35px;
  left: 70px;
  border-top: 1px solid #eed6a8;
  width: 60px;
  height: 50px;
  border-radius: 100%;
  z-index: 3;

  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

.butters .mouth {
  margin-top: 200px;
  position: relative;
  width: 30px;
  height: 25px;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-top-left-radius: 100%;
  margin-left: 110px;
  -webkit-transform: rotate(40deg);
  -moz-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  -o-transform: rotate(40deg);
  transform: rotate(40deg);

  -webkit-animation: closes_mouth 8s infinite step-start 0s;
  -moz-animation: closes_mouth 8s infinite step-start 0s;
  -o-animation: closes_mouth 8s infinite step-start 0s;
  -ms-animation: closes_mouth 8s infinite step-start 0s;
  animation: closes_mouth 8s infinite step-start 0s;
}

.butters .opened_mouth {
  position: relative;
  margin-left: 100px;
  margin-top: -20px;
  width: 40px;
  height: 10px;
  border-radius: 100%;
  background-color: #000;
  z-index: 4;
  opacity: 0 !important;

  -webkit-transform: rotate(15deg);
  -moz-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  -o-transform: rotate(15deg);
  transform: rotate(15deg);

  -webkit-animation: open_mouth 8s infinite step-start 0s;
  -moz-animation: open_mouth 8s infinite step-start 0s;
  -o-animation: open_mouth 8s infinite step-start 0s;
  -ms-animation: open_mouth 8s infinite step-start 0s;
  animation: open_mouth 8s infinite step-start 0s;
}
@-webkit-keyframes open_mouth {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  8% {
    opacity: 0;
  }
}
@-webkit-keyframes closes_mouth {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  8% {
    opacity: 1;
  }
}
.butters .body {
  position: relative;
  top: 210px;
  left: 65px;
  width: 160px;
  height: 200px;
  background: #3f506c;
  border: solid #3f506c;
  border-width: 0 3px 0 0;
  z-index: 1;

  -webkit-border-top-right-radius: 20px 120px;
  -moz-border-top-right-radius: 20px 120px;
  -ms-border-top-right-radius: 20px 120px;
  -o-border-top-right-radius: 20px 120px;
  border-top-right-radius: 20px 120px;

  -webkit-border-bottom-right-radius: 20px 120px;
  -moz-border-bottom-right-radius: 20px 120px;
  -ms-border-bottom-right-radius: 20px 120px;
  -o-border-bottom-right-radius: 20px 120px;
  border-bottom-right-radius: 20px 120px;

  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.butters .armpits {
  position: absolute;
  width: 70px;
  height: 190px;
  margin-top: 5px;
  background: #3f506c;
  z-index: 1;
}
.butters .ziper {
  position: absolute;
  top: 100px;
  left: 50px;
  width: 110px;
  height: 2px;
  background: #000;
  z-index: 10;
}
.butters .arm {
  position: relative;
  top: 135px;
  left: 40px;
  width: 50px;
  height: 150px;
  background: #3f506c;
  border: solid #000;
  border-width: 0 0 0 2px;

  -webkit-border-top-right-radius: 40px 120px;
  -moz-border-top-right-radius: 40px 120px;
  -ms-border-top-right-radius: 40px 120px;
  -o-border-top-right-radius: 40px 120px;
  border-top-right-radius: 40px 120px;

  -moz-border-bottom-right-radius: 40px 120px;
  -webkit-border-bottom-right-radius: 40px 120px;
  -ms-border-bottom-right-radius: 40px 120px;
  -o-border-bottom-right-radius: 40px 120px;
  border-bottom-right-radius: 40px 120px;

  -webkit-transform: rotate(110deg);
  -moz-transform: rotate(110deg);
  -ms-transform: rotate(110deg);
  -o-transform: rotate(110deg);
  transform: rotate(110deg);
}
.butters .arm:after {
  content: '';
  position: absolute;
  display: block;
  top: 75px;
  right: 200px;
  width: 50px;
  height: 150px;
  background: #3f506c;
  border: solid #000;
  border-width: 0 2px 0 0;

  -webkit-border-top-left-radius: 40px 120px;
  -moz-border-top-left-radius: 40px 120px;
  -ms-border-top-left-radius: 40px 120px;
  -o-border-top-left-radius: 40px 120px;
  border-top-left-radius: 40px 120px;

  -webkit-border-bottom-left-radius: 40px 120px;
  -moz-border-bottom-left-radius: 40px 120px;
  -ms-border-bottom-left-radius: 40px 120px;
  -o-border-bottom-left-radius: 40px 120px;
  border-bottom-left-radius: 40px 120px;

  -webkit-transform: rotate(-35deg);
  -moz-transform: rotate(-35deg);
  -ms-transform: rotate(-35deg);
  -o-transform: rotate(-35deg);
  transform: rotate(-35deg);
}
.butters .hand {
  position: absolute;
  top: -10px;
  left: -15px;
  width: 55px;
  height: 55px;
  background: #ffedcb;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.butters .hand:after {
  content: '';
  position: absolute;
  top: 80px;
  left: -220px;
  width: 55px;
  height: 55px;
  background: #ffedcb;
  z-index: 1;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}

.butters .thumb {
  position: absolute;
  top: 30px;
  left: 0px;
  width: 20px;
  height: 20px;
  background: #ffedcb;
  border: 1px solid #333;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  z-index: 4;
}

.butters .thumb:after {
  content: '';
  position: absolute;
  top: 70px;
  left: -180px;
  width: 20px;
  height: 20px;
  border: 1px solid #333;
  background: #ffedcb;
  z-index: 4;

  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
.butters .leg {
  position: relative;
  top: 160px;
  left: 54px;
  width: 185px;
  height: 50px;
  background: #4e714b;
  z-index: -3;
}
.butters .feet {
  position: absolute;
  top: 50px;
  left: -5px;
  width: 195px;
  height: 10px;
  background: #000;

  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}

/* End Butters */

/* Manage Media */
.btn-mm {
  color: #494949;
  background: #ccc;
}

.mm-active {
  background: #3b516b;
  color: white;
}

.mm-active:hover {
  background: #517096;
}

/* USER PROFILE */
.profile {
  padding: 20px;
  margin: 0;
  background: #0e0e0e;
  background: -moz-linear-gradient(top, #27282b 0%, #000000 100%);
  background: -webkit-linear-gradient(top, #27282b 0%, #000000 100%);
  background: linear-gradient(to bottom, #27282b 0%, #000000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27282b', endColorstr='#000000',GradientType=0 );
  box-shadow: inset 0 15px 25px -15px #4e4e4e;
  border-bottom: 1px solid #232323;
  display: flex;
}
.profile h1 {
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-weight: bold;
  color: #dadada;
  text-shadow: 2px 2px 2px black;
}

.profile h2 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
  color: #4e4e4e;
}

.profile h3 {
  margin: 9px 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  color: #9a9a9a;
  text-align: left;
}

.profile h4 {
  margin: 0 0 7px 0;
  padding: 0;
  font-size: 22px;
  font-weight: bold;
  color: #dadada;
  text-align: left;
  text-shadow: 2px 2px 2px black;
}

.profile h5 {
  margin: 3px 0 0 0;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  color: #9a9a9a;
  text-align: left;
}

.profile .avatar {
  width: 170px;
}

.profile img {
  border-radius: 50%;
  overflow: hidden;
}

.profile .buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
}

.no-content {
  text-align: center;
  font-size: 18px;
}

.no-content .big-icon {
  font-size: 160px;
  padding: 20px;
}

/* ChatBox */
.chatbox {
  position: fixed;
  bottom: 0;
  right: 20px;
  background: rgb(32, 32, 32);
  box-sizing: border-box;
  margin-bottom: 0px;
  z-index: 100;
}

.chatbox a {
  color: black;
}

.chatbox a:hover {
  text-decoration: underline;
}

.chatbox-wrap {
  width: 300px;
  display: flex;
  flex-direction: column;
  -webkit-transition: width 0.2s, height 0.2s;
  transition: width 0.2s, height 0.2s;
}

.chatbox-header {
  display: flex;
  padding: 7px 10px 5px 10px;
  justify-content: space-between;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
}

.chatbox-title {
  margin-top: 2px;
}

.chatbox-icon {
  font-size: 15px;
}

.icon {
  font-size: 18px;
  margin-top: -2px;
}

.chatbox-notify {
  background: #802424;
  padding: 2px 5px 1px 5px;
  color: white;
  font-size: 10px;
  text-align: center;
}

.chatbox-notify p {
  margin: 2px 0 0 0;
  padding: 0;
}

.chatbox-footer {
  display: flex;
  position: relative;
}

.empty {
  background: rgb(32, 32, 32) !important;
  color: rgb(32, 32, 32) !important;
}

.chatbox-content-wrap {
  position: relative;
  height: 359px;
  display: block;
}

.chatbox-content {
  /* display: flex;
  flex-direction: column; */
  background: white;
  height: 282px;
  color: #050505;
  overflow-y: auto;
  position: relative;
}

.chatbox-msg {
  display: flex;
  flex-direction: row;
  padding: 5px;
  font-size: 14px;
}

.chatbox-msg:nth-child(even) {
  background: #e4e4e4;
}

.chatbox-avatar {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  cursor: copy;
}

.chatbox-right {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  flex-grow: 1;
}

.chatbox-meta {
  display: flex;
  flex-direction: row;
}

.chatbox-name {
  font-weight: bold;
  font-size: 13px;
}

.chatbox-time {
  font-size: 10px;
  margin: 3px 0 0 auto;
  color: #656565;
}

.chatbox-isTyping {
  font-size: 10px;
  text-align: center;
  padding: 3px 0;
  height: 18px;
  background: white;
  color: #313131;
  border-top: 1px solid #bbbbbb;
}

.chatbox-text {
  font-size: 13px;
  margin-top: 2px;
  line-height: 1.3em;
  max-width: 230px;
  word-wrap: break-word;
}

.chatbox-footer {
  display: flex;
  border-top: 1px solid #b5b5b5;
  position: fixed;
  bottom: 0;
  width: 300px;
  box-sizing: border-box;
  height: 59px;
}

.chatbox-input {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-grow: 1;
  position: relative;
  background: white;
}

.chatbox-input textarea {
  padding: 5px;
  width: 100%;
  outline: none;
  border: none;
  resize: none;
  font-size: 13px;
  box-sizing: border-box;
  font-family: Arial;
}

.emoji-btn-wrap {
  background: white;
}

.emoji-btn {
  border: none;
  font-size: 16px;
  color: #969696;
  margin-top: 4px;
  cursor: pointer;
  transition: 0.2s;
}

.emoji-btn:hover {
  color: #333333;
}

/* EmojiMart */
.emoji-mart {
  border: none !important;
  border-radius: 0px !important;
}

.emoji-mart .emoji-mart-emoji {
  padding: 3px !important;
}

.emoji-mart-search input {
  font-size: 13px !important;
}

.emoji-mart * {
  line-height: 0.5 !important;
}

.emoji-mart-bar:first-child {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.emoji-mart-category-label {
  font-size: 13px !important;
}

.emoji-mart-category-label span {
  padding: 8px 6px !important;
}

.emoji-mart-preview {
  display: none !important;
}

.emoji-mart-bar {
  border: none !important;
}

.emoji-mart-anchor {
  padding: 5px 4px !important;
}

.emoji-mart-scroll {
  height: 219px !important;
}

/* My Account */
.ac-profile-wrap {
  background: #0a0a0a;
  padding: 20px;
}
.ac-profile-wrap h2,
.ac-show-wrap h2,
.ac-files-wrap h2 {
  text-align: center;
  font-size: 16px;
  color: #a7a7a7;
  margin: 0;
}
.layout {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}

.layout .avatar {
  margin-right: 20px;
}

.layout .avatar img {
  border-radius: 50%;
  overflow: hidden;
  height: 100px;
  width: 100px;
}

.layout .meta {
  display: flex;
  flex-direction: column;
}

.layout .meta > div {
  margin: 10px 0 0 0;
}

.ac-meta > div {
  margin: 10px 0 0 0;
}

.ac-title {
  font-size: 20px;
  font-weight: bold;
}

.ac-live {
  display: flex;
}

.ac-live .enabled {
  font-size: 12px;
  margin: 6px 0 0 10px;
  color: #609e60;
}

.ac-live .disabled {
  font-size: 12px;
  margin: 6px 0 0 10px;
  color: #983d3d;
}

.acc-email .btn,
.live-pass .btn {
  padding: 2px 6px;
  font-size: 11px;
  background: none;
  color: #797979;
}

.acc-email .btn:hover,
.live-pass .btn:hover {
  background: none;
  color: orange;
}

.acc-email .emailInput {
  font-size: 14px;
  width: 350px;
  outline: none;
  padding: 2px;
}

.ac-show-wrap {
  background: #0e0e0e;
  padding: 20px;
  border-top: 1px solid #1b1b1b;
}

.ac-show-wrap .passInfo {
  font-size: 12px;
  margin-top: 10px;
  color: #5b82c1;
}

.lb-connection {
  font-size: 13px;
}

.lb-notice {
  background: #020202;
  padding: 20px 20px 10px 20px;
  font-size: 13px;
  box-shadow: 1px 1px 1px #171717;
}

.lb-notice .notice {
  color: #f36d19;
  margin-bottom: 10px;
}

.lb-notice div {
  margin-bottom: 10px;
}

.ac-show-wrap .passInput {
  font-size: 14px;
  width: 150px;
  outline: none;
  padding: 2px;
}

.ac-show-wrap .multi {
  font-size: 12px;
  color: orange;
}

.ac-files-wrap {
  background: none;
  padding: 20px;
  border-top: 1px solid #1b1b1b;
}

.ac-files-wrap > div {
  margin: 10px 0 0 0;
}

.live-switch:focus + label {
  outline: none;
}

.live-switch {
  display: none;
}

.ls-label {
  position: relative;
  width: 50px;
  height: 24px;
  background-color: black;
  display: inline-block;
  font-size: 0;
  border-radius: 20px;
  transition: background-color 0.2s ease-in-out;
  border: 2px solid black;
  cursor: pointer;
  box-shadow: 1px 1px 1px #2f2f2f;
}
.ls-label::before {
  content: '';
  position: absolute;
  top: 0rem;
  left: 0rem;
  height: 20px;
  width: 20px;
  background: #e2e2e2;
  background: -moz-linear-gradient(
    -45deg,
    #e2e2e2 0%,
    #dbdbdb 50%,
    #d1d1d1 51%,
    #fefefe 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #e2e2e2 0%,
    #dbdbdb 50%,
    #d1d1d1 51%,
    #fefefe 100%
  );
  background: linear-gradient(
    135deg,
    #e2e2e2 0%,
    #dbdbdb 50%,
    #d1d1d1 51%,
    #fefefe 100%
  );
  border-radius: 50%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.live-switch:checked + .ls-label {
  background: #627d4d;
  background: -moz-linear-gradient(top, #627d4d 0%, #101e04 100%);
  background: -webkit-linear-gradient(top, #627d4d 0%, #101e04 100%);
  background: linear-gradient(to bottom, #627d4d 0%, #101e04 100%);
}
.live-switch:checked + .noPass {
  background: #4e2929;
}
.live-switch:checked + .ls-label::before {
  -webkit-transform: translate3d(25px, 0, 0);
  transform: translate3d(25px, 0, 0);
}

/* Icecast Settings */
.ice-settings {
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
}
.ice-heading {
  background: #353535;
  color: white;
  font-weight: bold;
  padding: 5px;
  text-align: center;
  font-size: 13px;
  margin: 20px 0 1px 0;
  border-top: 1px solid #424242;
}
.ice-flex {
  display: flex;
  width: 100%;
  align-content: space-between;
  flex-flow: row wrap;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.ice-flex .cell {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0;
  flex-grow: 1;
}

.ice-flex .title {
  font-size: 10px;
  font-weight: bold;
  padding: 5px;
  background: #242424;
  min-width: 190px;
}

.ice-flex .readOnly {
  font-size: 12px;
  padding: 6px;
  text-align: center;
  color: black;
  background: white;
}

.ice-flex input {
  display: flex;
  font-size: 12px;
  padding: 3px;
  text-align: center;
  flex-grow: 1;
}

.ice-flex select {
  display: flex;
  font-size: 12px;
  padding: 3px 5px;
  text-align: center;
  flex-grow: 1;
  border: 0;
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0px;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjMwNnB4IiBoZWlnaHQ9IjMwNnB4IiB2aWV3Qm94PSIwIDAgMzA2IDMwNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzA2IDMwNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGcgaWQ9ImV4cGFuZC1tb3JlIj4NCgkJPHBvbHlnb24gcG9pbnRzPSIyNzAuMyw1OC42NSAxNTMsMTc1Ljk1IDM1LjcsNTguNjUgMCw5NC4zNSAxNTMsMjQ3LjM1IDMwNiw5NC4zNSAJCSIvPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
  background-position: 99% 50%;
  background-repeat: no-repeat;
  background-size: 14px;
  text-align-last: center;
}

.ice-settings-save {
  margin: 0 0 50px 0;
  text-align: center;
}

.conf-gen {
  text-align: center;
  background: #242424;
  padding: 5px;
  font-size: 13px;
  border-top: 1px solid #333333;
  border-bottom: 1px solid #000000;
}

.icecast-settings-info {
  text-align: center;
  font-size: 12px;
  color: #c37e32;
  margin-bottom: 20px;
}

/* Loader */
.loader-container {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 200px;
  height: 200px;
}
.loader path {
  fill: #9e360a;
  transform-origin: center;
  animation: rotate 0.8s linear infinite;
}
.loaderreverse {
  animation-direction: reverse !important;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
  from {
    transform: rotate(0deg);
  }
}

/* SignalSwitch */
.switch-box {
  padding: 5px 20px 5px 18px;
  width: 100px;
}
.source-head {
  font-size: 9px;
  color: #616161;
  padding: 4px 0;
  font-weight: bold;
  text-align: center;
}

.source-box {
  width: 100%;
  background: #383838;
  padding: 2px 5px 1px 5px;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  color: black;
  cursor: help;
}

.source-box-2 {
  width: 100%;
  background: #383838;
  padding: 2px 5px 1px 5px;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  color: black;
  margin-top: 4px;
  cursor: help;
}

.s-ices {
  background: #4f829a;
  color: white;
}

.s-live {
  background: #a55d30;
  color: white;
  -webkit-animation-name: livePulse;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-name: livePulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@-webkit-keyframes livePulse {
  from {
    background-color: #bc330d;
    -webkit-box-shadow: 0 0 9px #333;
  }
  50% {
    background-color: #e33100;
    -webkit-box-shadow: 0 0 18px #e33100;
  }
  to {
    background-color: #bc330d;
    -webkit-box-shadow: 0 0 9px #333;
  }
}

@keyframes livePulse {
  from {
    background-color: #bc330d;
    -webkit-box-shadow: 0 0 9px #333;
  }
  50% {
    background-color: #e33100;
    -webkit-box-shadow: 0 0 18px #e33100;
  }
  to {
    background-color: #bc330d;
    -webkit-box-shadow: 0 0 9px #333;
  }
}

/* Stan */
#stan {
  top: 30px;
  left: 40px;
  margin: 0 auto;
  position: relative;
  height: 300px;
  width: 300px;
}
#stan * {
  position: absolute;
}
#stan .head {
  top: 20px;
  width: 220px;
  height: 220px;
  background: #dcc8b3;
  border-radius: 50%;
}
#stan .hat {
  top: -1px;
  width: 220px;
  height: 110px;
  background: #3f4d71;
  border-radius: 220px 220px 0 0;
}
#stan .hat:after {
  content: '';
  position: absolute;
  top: 87px;
  width: 220px;
  height: 60px;
  border-radius: 218px / 50px;
  background: #dcc8b3;
  box-shadow: 0px -18px 0px 3px #a93d3d;
  left: 0;
}
#stan .hat .hat-l-side {
  top: 90px;
  left: -3px;
  width: 20px;
  height: 20px;
  background: #a93d3d;
  -webkit-transform: rotate(-8deg);
  -ms-transform: rotate(-8deg);
  transform: rotate(-8deg);
}
#stan .hat .hat-r-side {
  top: 90px;
  right: -1px;
  width: 20px;
  height: 20px;
  background: #a93d3d;
  -webkit-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  transform: rotate(8deg);
}
#stan .hat .hat-top-1 {
  top: -3px;
  left: 84px;
  width: 50px;
  height: 6px;
  background: #a93d3d;
  -webkit-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  transform: rotate(-20deg);
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  z-index: 10;
}
#stan .hat .hat-top-2 {
  top: -3px;
  left: 84px;
  width: 50px;
  height: 6px;
  background: #a93d3d;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  border-radius: 2px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.7);
  z-index: 9;
}
#stan .hat .hat-top-3 {
  top: -3px;
  left: 84px;
  width: 50px;
  height: 6px;
  background: #a93d3d;
  -webkit-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
  border-radius: 2px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.7);
  z-index: 8;
}
#stan .hat .hat-top-4 {
  top: -3px;
  left: 84px;
  width: 50px;
  height: 6px;
  background: #a93d3d;
  -webkit-transform: rotate(67deg);
  -ms-transform: rotate(67deg);
  transform: rotate(67deg);
  border-radius: 2px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.7);
  z-index: 7;
}
#stan .hat .hat-top-5 {
  top: -3px;
  left: 84px;
  width: 50px;
  height: 6px;
  background: #a93d3d;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 2px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.7);
  z-index: 6;
}
#stan .hat .hat-top-6 {
  top: -3px;
  left: 84px;
  width: 50px;
  height: 6px;
  background: #a93d3d;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-radius: 2px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.7);
  z-index: 5;
}
#stan .hat .hat-top-7 {
  top: -3px;
  left: 84px;
  width: 50px;
  height: 6px;
  background: #a93d3d;
  -webkit-transform: rotate(-67deg);
  -ms-transform: rotate(-67deg);
  transform: rotate(-67deg);
  border-radius: 2px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.7);
  z-index: 4;
}
#stan .hat .hat-top-8 {
  top: -3px;
  left: 84px;
  width: 50px;
  height: 6px;
  background: #a93d3d;
  border-radius: 2px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.7);
  z-index: 3;
}
#stan .left-eye {
  top: 90px;
  left: 60px;
  height: 60px;
  width: 50px;
  background: #fff;
  border-radius: 50px / 60px;
  border: 1px solid #bfb18e;
  box-shadow: -1px -2px 0px -1px #c8b899;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  overflow: hidden;
}
#stan .left-eye:after {
  content: '';
  position: absolute;
  left: 25px;
  top: 22px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #312f3c;
  z-index: 0;
}
#stan .left-eye .eyelid-top {
  top: -32px;
  left: 0px;
  width: 50px;
  height: 30px;
  border-bottom: 1px solid #c8b899;
  border-radius: 50% 50% 0 0;
  background: #ffe3c6;
  z-index: 5;
  -webkit-animation-name: stanlefteyelidtop;
  -webkit-animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-name: stanlefteyelidtop;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
#stan .left-eye .eyelid-bottom {
  bottom: -32px;
  left: 0px;
  width: 50px;
  height: 30px;
  border-top: 1px solid #c8b899;
  border-radius: 0 0 50% 50%;
  background: #ffe3c6;
  z-index: 5;
  -webkit-animation-name: stanlefteyelidbottom;
  -webkit-animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-name: stanlefteyelidbottom;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
#stan .right-eye {
  top: 90px;
  left: 110px;
  height: 60px;
  width: 50px;
  background: #fff;
  border-radius: 50px / 60px;
  border: 1px solid #bfb18e;
  box-shadow: 1px -2px 0px -1px #c8b899;
  -webkit-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  transform: rotate(-30deg);
  overflow: hidden;
}
#stan .right-eye:after {
  content: '';
  position: absolute;
  left: 18px;
  top: 20px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #312f3c;
  z-index: 0;
}
#stan .right-eye .eyelid-top {
  top: -32px;
  left: 0px;
  width: 50px;
  height: 30px;
  border-bottom: 1px solid #c8b899;
  border-radius: 50% 50% 0 0;
  background: #ffe3c6;
  z-index: 5;
  -webkit-animation-name: stanrighteyelidtop;
  -webkit-animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-name: stanrighteyelidtop;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
#stan .right-eye .eyelid-bottom {
  bottom: -32px;
  left: 0px;
  width: 50px;
  height: 30px;
  border-top: 1px solid #c8b899;
  border-radius: 0 0 50% 50%;
  background: #ffe3c6;
  z-index: 5;
  -webkit-animation-name: stanrighteyelidbottom;
  -webkit-animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-name: stanrighteyelidbottom;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
#stan .mouth {
  bottom: 10px;
  left: 95px;
  width: 35px;
  height: 25px;
  border-radius: 50%;
  background: #dcc8b3;
  box-shadow: 0px 3px 0px -1px #000;
  transform: rotate(180deg);
}

@-webkit-keyframes stanrighteyelidtop {
  0% {
    top: -32px;
  }
  2% {
    top: 0px;
  }
  4% {
    top: -32px;
  }
  100% {
    top: -32px;
  }
}
@keyframes stanrighteyelidtop {
  0% {
    top: -32px;
  }
  2% {
    top: 0px;
  }
  4% {
    top: -32px;
  }
  100% {
    top: -32px;
  }
}
@-webkit-keyframes stanlefteyelidtop {
  0% {
    top: -32px;
  }
  2% {
    top: 0px;
  }
  4% {
    top: -32px;
  }
  100% {
    top: -32px;
  }
}
@keyframes stanlefteyelidtop {
  0% {
    top: -32px;
  }
  2% {
    top: 0px;
  }
  4% {
    top: -32px;
  }
  100% {
    top: -32px;
  }
}
@-webkit-keyframes stanlefteyelidbottom {
  0% {
    bottom: -32px;
  }
  2% {
    bottom: 0px;
  }
  4% {
    bottom: -32px;
  }
  100% {
    bottom: -32px;
  }
}
@keyframes stanlefteyelidbottom {
  0% {
    bottom: -32px;
  }
  2% {
    bottom: 0px;
  }
  4% {
    bottom: -32px;
  }
  100% {
    bottom: -32px;
  }
}
@-webkit-keyframes stanrighteyelidbottom {
  0% {
    bottom: -32px;
  }
  2% {
    bottom: 0px;
  }
  4% {
    bottom: -32px;
  }
  100% {
    bottom: -32px;
  }
}
@keyframes stanrighteyelidbottom {
  0% {
    bottom: -32px;
  }
  2% {
    bottom: 0px;
  }
  4% {
    bottom: -32px;
  }
  100% {
    bottom: -32px;
  }
}

/* Kenny */
#kenny {
  position: relative;
  box-sizing: border-box;
  height: 260px;
  width: 100%;
  top: 40px;
  margin-bottom: 10px;
}
.kenny_head {
  margin: 0 auto;
  background: #f4c992;
  height: 200px;
  width: 200px;
  position: relative;
  border-radius: 50%;
  box-shadow: 1px 1px 15px -3px black;
  z-index: 4;
}
.kenny_head:before {
  border-radius: 50%;
  content: ' ';
  position: absolute;
  top: 25px;
  left: 2px;
  right: -30px;
  bottom: 25px;
  border-left: solid 45px #653c1a;
  z-index: 3;
}
.kenny_head:after {
  border-radius: 50%;
  content: ' ';
  position: absolute;
  top: 25px;
  left: -30px;
  right: 0;
  bottom: 25px;
  border-right: solid 45px #653c1a;
  z-index: 3;
}
.kenny_head .kenny_hoodie {
  position: absolute;
  height: 255px;
  width: 280px;
  border-radius: 50%;
  border: 40px solid #bc6000;
  -webkit-transform: scale(0.79);
  transform: scale(0.79);
  -webkit-transform-origin: -21% 0%;
  transform-origin: -21% 0%;
  z-index: 30;
}
.kenny_head .kenny_left_eye {
  position: absolute;
  height: 90px;
  width: 70px;
  background: white;
  border-radius: 65% 50%;
  box-shadow: 1px 1px 15px -5px gray;
  border-bottom: 1px solid gray;
  top: 55px;
  left: 30px;
}
.kenny_head .kenny_left_eye:after {
  content: ' ';
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #1d1d1d;
  top: 40px;
  left: 55px;
  -webkit-animation: kenny_pupil_left 6s infinite step-start 0s;
  animation: kenny_pupil_left 6s infinite step-start 0s;
}
.kenny_head .kenny_left_eye .kenny_left_eye_closed {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 70px;
  height: 90px;
  background-color: #f4c992;
  opacity: 0;
  z-index: 2;
  border-radius: 65% 50%;
  -webkit-animation: kenny_close_eyes 4s infinite step-start 0s;
  animation: kenny_close_eyes 4s infinite step-start 0s;
}
.kenny_head .kenny_left_eye .kenny_left_eye_closed:after {
  content: '';
  position: absolute;
  height: 5px;
  width: 51px;
  top: 44px;
  left: 17px;
  border-style: solid none none none;
  border-width: 1px 0 0 0;
  border-color: #1d1d1d;
  border-radius: 50px 50px 16px 16px;
}
.kenny_head .kenny_right_eye {
  position: absolute;
  height: 90px;
  width: 70px;
  background: white;
  border-radius: 65% 50%;
  box-shadow: 1px 1px 15px -5px gray;
  border-top: 1px solid gray;
  top: 55px;
  left: 100px;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.kenny_head .kenny_right_eye:after {
  content: ' ';
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #1d1d1d;
  top: 40px;
  left: 5px;
  -webkit-animation: kenny_pupil_right 6s infinite step-start 0s;
  animation: kenny_pupil_right 6s infinite step-start 0s;
}
.kenny_head .kenny_right_eye .kenny_right_eye_closed {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 70px;
  height: 90px;
  background-color: #f4c992;
  opacity: 0;
  z-index: 2;
  border-radius: 65% 50%;
  -webkit-animation: kenny_close_eyes 4s infinite step-start 0s;
  animation: kenny_close_eyes 4s infinite step-start 0s;
}
.kenny_head .kenny_right_eye .kenny_right_eye_closed:after {
  content: '';
  position: absolute;
  height: 5px;
  width: 51px;
  top: 40px;
  left: 4px;
  border-style: none none solid none;
  border-width: 0 0 1px 0;
  border-color: #1d1d1d;
  border-radius: 16px 16px 50px 50px;
}

.kenny_lace {
  position: absolute;
  top: 150px;
  left: 30px;
  width: 60px;
  height: 90px;
  border: solid 5px #1d1d1d;
  border-color: #1d1d1d transparent transparent transparent;
  border-radius: 50px 100px 50px 0;
  -webkit-transform: rotate(80deg);
  transform: rotate(80deg);
  z-index: 30;
}
.kenny_lace:before {
  content: ' ';
  position: absolute;
  top: -18px;
  left: -1px;
  width: 50px;
  height: 20px;
  border: solid 5px #1d1d1d;
  border-color: transparent transparent #1d1d1d transparent;
  border-radius: 10% 100%;
}

@-webkit-keyframes kenny_pupil_left {
  0% {
    margin-top: 0px;
  }
  30% {
    margin-top: 5px;
  }
  100% {
    margin-top: 0px;
  }
}

@keyframes kenny_pupil_left {
  0% {
    margin-top: 0px;
  }
  30% {
    margin-top: 5px;
  }
  100% {
    margin-top: 0px;
  }
}
@-webkit-keyframes kenny_pupil_right {
  0% {
    margin-top: 0px;
  }
  30% {
    margin-top: -5px;
  }
  100% {
    margin-top: 0px;
  }
}
@keyframes kenny_pupil_right {
  0% {
    margin-top: 0px;
  }
  30% {
    margin-top: -5px;
  }
  100% {
    margin-top: 0px;
  }
}
@-webkit-keyframes kenny_close_eyes {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  8% {
    opacity: 0;
  }
}

@keyframes kenny_close_eyes {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  8% {
    opacity: 0;
  }
}

/* Cartman */
#cartman {
  top: 30px;
  left: 40px;
  margin: 0 auto;
  position: relative;
  height: 270px;
  width: 300px;
}
#cartman * {
  position: absolute;
}
#cartman .head {
  top: 20px;
  width: 220px;
  height: 180px;
  background: #e6d7af;
  border-radius: 50%;
}
#cartman .hat {
  top: 0;
  width: 220px;
  height: 90px;
  background: #166f73;
  border-radius: 220px 220px 0 0;
}
#cartman .hat:after {
  content: '';
  position: absolute;
  top: 55px;
  width: 220px;
  height: 70px;
  border-radius: 220px / 70px;
  background: #e6d7af;
  box-shadow: 0px -8px 0px 1px #cab842;
  left: 0;
}
#cartman .hat .hat-top-1 {
  top: -10px;
  left: 75px;
  width: 40px;
  height: 10px;
  border-radius: 20px / 5px;
  background: #cab842;
  -webkit-transform: rotate(-20deg) skew(-35deg);
  -ms-transform: rotate(-20deg) skew(-35deg);
  transform: rotate(-20deg) skew(-35deg);
}
#cartman .hat .hat-top-2 {
  top: -10px;
  left: 95px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #cab842;
}
#cartman .hat .hat-top-3 {
  top: -10px;
  left: 90px;
  width: 50px;
  height: 15px;
  border-radius: 25px / 5px;
  background: #cab842;
  box-shadow: 2px 2px 0px -3px #5f5f5f;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}
#cartman .left-eye {
  top: 57px;
  left: 60px;
  height: 60px;
  width: 50px;
  background: #fff;
  border-radius: 50px / 60px;
  border: 1px solid #f8e8c1;
  box-shadow: -1px -2px 0px -1px #c8b899;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  overflow: hidden;
}
#cartman .left-eye:after {
  content: '';
  position: absolute;
  left: 25px;
  top: 22px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #312f3c;
  z-index: 0;
}
#cartman .left-eye .eyelid-top {
  top: -32px;
  left: 0px;
  width: 50px;
  height: 30px;
  border-bottom: 1px solid #c8b899;
  border-radius: 50% 50% 0 0;
  background: #e6d7af;
  z-index: 5;
  -webkit-animation-name: cartmanlefteyelidtop;
  -webkit-animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-name: cartmanlefteyelidtop;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
#cartman .left-eye .eyelid-bottom {
  bottom: -32px;
  left: 0px;
  width: 50px;
  height: 30px;
  border-top: 1px solid #c8b899;
  border-radius: 0 0 50% 50%;
  background: #e6d7af;
  z-index: 5;
  -webkit-animation-name: cartmanlefteyelidbottom;
  -webkit-animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-name: cartmanlefteyelidbottom;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
#cartman .right-eye {
  top: 57px;
  left: 110px;
  height: 60px;
  width: 50px;
  background: #fff;
  border-radius: 50px / 60px;
  border: 1px solid #f8e8c1;
  box-shadow: 1px -2px 0px -1px #c8b899;
  -webkit-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  transform: rotate(-30deg);
  overflow: hidden;
}
#cartman .right-eye:after {
  content: '';
  position: absolute;
  left: 18px;
  top: 20px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #312f3c;
  z-index: 0;
}
#cartman .right-eye .eyelid-top {
  top: -32px;
  left: 0px;
  width: 50px;
  height: 30px;
  border-bottom: 1px solid #c8b899;
  border-radius: 50% 50% 0 0;
  background: #e6d7af;
  z-index: 5;
  -webkit-animation-name: cartmanrighteyelidtop;
  -webkit-animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-name: cartmanrighteyelidtop;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
#cartman .right-eye .eyelid-bottom {
  bottom: -32px;
  left: 0px;
  width: 50px;
  height: 30px;
  border-top: 1px solid #c8b899;
  border-radius: 0 0 50% 50%;
  background: #e6d7af;
  z-index: 5;
  -webkit-animation-name: cartmanrighteyelidbottom;
  -webkit-animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-play-state: running;
  animation-name: cartmanrighteyelidbottom;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
#cartman .chin-top {
  bottom: 20px;
  left: 90px;
  width: 40px;
  height: 8px;
  border-radius: 20px / 4px;
  background: #e6d7af;
  box-shadow: 0px 2px 0px -1px #b9a47a;
}
#cartman .chin-bottom {
  bottom: 12px;
  left: 20px;
  width: 180px;
  height: 100px;
  border-radius: 90px / 50px;
  background: transparent;
  box-shadow: 0px 2px 0px -1px #b9a47a;
}
#cartman .mouth {
  bottom: 30px;
  left: 98px;
  width: 25px;
  height: 10px;
  border-radius: 50%;
  background: black;
}

@-webkit-keyframes cartmanrighteyelidtop {
  0% {
    top: -32px;
  }
  2% {
    top: 0px;
  }
  4% {
    top: -32px;
  }
  100% {
    top: -32px;
  }
}
@keyframes cartmanrighteyelidtop {
  0% {
    top: -32px;
  }
  2% {
    top: 0px;
  }
  4% {
    top: -32px;
  }
  100% {
    top: -32px;
  }
}
@-webkit-keyframes cartmanlefteyelidtop {
  0% {
    top: -32px;
  }
  2% {
    top: 0px;
  }
  4% {
    top: -32px;
  }
  100% {
    top: -32px;
  }
}
@keyframes cartmanlefteyelidtop {
  0% {
    top: -32px;
  }
  2% {
    top: 0px;
  }
  4% {
    top: -32px;
  }
  100% {
    top: -32px;
  }
}
@-webkit-keyframes cartmanlefteyelidbottom {
  0% {
    bottom: -32px;
  }
  2% {
    bottom: 0px;
  }
  4% {
    bottom: -32px;
  }
  100% {
    bottom: -32px;
  }
}
@keyframes cartmanlefteyelidbottom {
  0% {
    bottom: -32px;
  }
  2% {
    bottom: 0px;
  }
  4% {
    bottom: -32px;
  }
  100% {
    bottom: -32px;
  }
}
@-webkit-keyframes cartmanrighteyelidbottom {
  0% {
    bottom: -32px;
  }
  2% {
    bottom: 0px;
  }
  4% {
    bottom: -32px;
  }
  100% {
    bottom: -32px;
  }
}
@keyframes cartmanrighteyelidbottom {
  0% {
    bottom: -32px;
  }
  2% {
    bottom: 0px;
  }
  4% {
    bottom: -32px;
  }
  100% {
    bottom: -32px;
  }
}

/* Ghost */
#ghost {
  margin: 30px 0 40px 0;
}

.ghost_ghost {
  --eye-size: 45px;
  --body-width: 150px;

  display: inline-block;
  margin: 20px;
}

.ghost_body {
  border-radius: 50% 50% 0 0;
  height: 150px;
  width: var(--body-width);
  position: relative;
}

.ghost_eyes {
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.ghost_eye {
  height: var(--eye-size);
  width: var(--eye-size);
  background: white;
  border-radius: 100%;
  margin: 0 5px;
  position: relative;
}

.ghost_pupil {
  height: calc(var(--eye-size) / 2);
  width: calc(var(--eye-size) / 2);
  background: black;
  border-radius: 100%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ghost_body-lower {
  width: 100%;
  height: 25px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(90%);
}

.ghost_spikes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  position: absolute;
  bottom: 0;
  left: 0;
}

.ghost_spike {
  --x: calc(var(--body-width) / 6);
  border-bottom: solid var(--x) #131313;
  border-left: solid var(--x) transparent;
  border-right: solid var(--x) transparent;
  border-top: solid var(--x) transparent;
}

.ghost_blinky .ghost_body,
.ghost_blinky .ghost_body-lower {
  background: #983535;
}

.ghost_ghost .ghost_pupil {
  animation: side2side 1.2s alternate infinite linear;
}
@keyframes side2side {
  0% {
    transform: translate(-80%, -50%);
  }

  25% {
    transform: translate(-20%, -50%);
  }

  50% {
    transform: translate(-80%, -50%);
  }

  100% {
    transform: translate(-20%, -50%);
  }
}

.ghost_ghost {
  animation: hover 1.2s alternate infinite;
}
@keyframes hover {
  0% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0px);
  }
}

/* settings reboot services */
.reboot-heading {
  background: #353535;
  color: white;
  font-weight: bold;
  padding: 5px;
  text-align: center;
  font-size: 13px;
  margin: 0 0 1px 0;
  border-top: 1px solid #424242;
}

.reboot-options {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.reboot-options select {
  display: flex;
  font-size: 12px;
  padding: 3px 5px;
  text-align: center;
  flex-grow: 1;
  border: 0;
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0px;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjMwNnB4IiBoZWlnaHQ9IjMwNnB4IiB2aWV3Qm94PSIwIDAgMzA2IDMwNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzA2IDMwNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGcgaWQ9ImV4cGFuZC1tb3JlIj4NCgkJPHBvbHlnb24gcG9pbnRzPSIyNzAuMyw1OC42NSAxNTMsMTc1Ljk1IDM1LjcsNTguNjUgMCw5NC4zNSAxNTMsMjQ3LjM1IDMwNiw5NC4zNSAJCSIvPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
  background-position: 99% 50%;
  background-repeat: no-repeat;
  background-size: 14px;
  text-align-last: center;
}

.reboot-options .sel-srt {
  margin-right: 50px;
}

/* login as */
.login-as {
  display: flex;
  padding: 20px;
}

.login-as select {
  display: flex;
  font-size: 12px;
  padding: 3px 5px;
  text-align: center;
  flex-grow: 1;
  border: 0;
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0px;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjMwNnB4IiBoZWlnaHQ9IjMwNnB4IiB2aWV3Qm94PSIwIDAgMzA2IDMwNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzA2IDMwNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGcgaWQ9ImV4cGFuZC1tb3JlIj4NCgkJPHBvbHlnb24gcG9pbnRzPSIyNzAuMyw1OC42NSAxNTMsMTc1Ljk1IDM1LjcsNTguNjUgMCw5NC4zNSAxNTMsMjQ3LjM1IDMwNiw5NC4zNSAJCSIvPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K');
  background-position: 99% 50%;
  background-repeat: no-repeat;
  background-size: 14px;
  text-align-last: center;
}
